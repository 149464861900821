import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import * as genderData from "./../../enums/Genders";
import * as Travelmode from "./../../enums/Travelmodes";
import exportData from "../../services/exportToExcelService";
import { Dropdown } from "primereact/dropdown";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";
import "../../../src/assets/layout/styling.css";
import moment from "moment";

const BookTickets = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [id, setid] = useState(new Array());
    const [files, setFiles] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selecteddata, setselecteddata] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterstate, setFilterstate] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [ticketDialog, setTicketdialog] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [materialFile, setmatierialfile] = useState([]);
    const [infare, setinfare] = useState(null);
    const [outfare, setoutfare] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [ActionDisable, setActionDisable] = useState(true);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [Cityfilter, setCityfilter] = useState(null);
    const [Statefilter, setStatefilter] = useState(null);
    const [Countryfilter, setCountryfilter] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);

    const [InBatchfilter, setInBatchfilter] = useState(null);
    const [OutBatchfilter, setOutBatchfilter] = useState(null);
    const [InFlightNo1filter, setInFlightNo1filter] = useState(null);
    const [InAirlineName1filter, setInAirlineName1filter] = useState(null);
    const [InDepartFrom1filter, setInDepartFrom1filter] = useState(null);
    const [InArriveAt1filter, setInArriveAt1filter] = useState(null);

    const [InFlightNo2filter, setInFlightNo2filter] = useState(null);
    const [InAirlineName2filter, setInAirlineName2filter] = useState(null);
    const [InDepartFrom2filter, setInDepartFrom2filter] = useState(null);
    const [InArriveAt2filter, setInArriveAt2filter] = useState(null);

    const [InFlightNo3filter, setInFlightNo3filter] = useState(null);
    const [InAirlineName3filter, setInAirlineName3filter] = useState(null);
    const [InDepartFrom3filter, setInDepartFrom3filter] = useState(null);
    const [InArriveAt3filter, setInArriveAt3filter] = useState(null);

    const [InFlightNo4filter, setInFlightNo4filter] = useState(null);
    const [InAirlineName4filter, setInAirlineName4filter] = useState(null);
    const [InDepartFrom4filter, setInDepartFrom4filter] = useState(null);
    const [InArriveAt4filter, setInArriveAt4filter] = useState(null);

    const [OutFlightNo1filter, setOutFlightNo1filter] = useState(null);
    const [OutAirlineName1filter, setOutAirlineName1filter] = useState(null);
    const [OutDepartFrom1filter, setOutDepartFrom1filter] = useState(null);
    const [OutArriveAt1filter, setOutArriveAt1filter] = useState(null);

    const [OutFlightNo2filter, setOutFlightNo2filter] = useState(null);
    const [OutAirlineName2filter, setOutAirlineName2filter] = useState(null);
    const [OutDepartFrom2filter, setOutDepartFrom2filter] = useState(null);
    const [OutArriveAt2filter, setOutArriveAt2filter] = useState(null);

    const [OutFlightNo3filter, setOutFlightNo3filter] = useState(null);
    const [OutAirlineName3filter, setOutAirlineName3filter] = useState(null);
    const [OutDepartFrom3filter, setOutDepartFrom3filter] = useState(null);
    const [OutArriveAt3filter, setOutArriveAt3filter] = useState(null);

    const [OutFlightNo4filter, setOutFlightNo4filter] = useState(null);
    const [OutAirlineName4filter, setOutAirlineName4filter] = useState(null);
    const [OutDepartFrom4filter, setOutDepartFrom4filter] = useState(null);
    const [OutArriveAt4filter, setOutArriveAt4filter] = useState(null);

    const fileUploadRef = useRef(null);
    const toast = useRef(null);
    const dt = useRef(null);
    var company = localStorage.getItem("Company");
    var usrDetails = userDetails();
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        name: null,
        city: null,
        boardingCity: null,
        state: null,
        country: null,
        email: null,
        filterHospitalName: null,
        filterCompany: null,
        filterMobileNo: null,

        inbatchfilter: null,
        outbatchfilter: null,

        InFlightNo1filter: null,
        InAirlineName1filter: null,
        InDepartFrom1filter: null,
        InArriveAt1filter: null,

        InFlightNo2filter: null,
        InAirlineName2filter: null,
        InDepartFrom2filter: null,
        InArriveAt2filter: null,

        InFlightNo3filter: null,
        InAirlineName3filter: null,
        InDepartFrom3filter: null,
        InArriveAt3filter: null,

        InFlightNo4filter: null,
        InAirlineName4filter: null,
        InDepartFrom4filter: null,
        InArriveAt4filter: null,

        OutFlightNo1filter: null,
        OutAirlineName1filter: null,
        OutDepartFrom1filter: null,
        OutArriveAt1filter: null,

        OutFlightNo2filter: null,
        OutAirlineName2filter: null,
        OutDepartFrom2filter: null,
        OutArriveAt2filter: null,

        OutFlightNo3filter: null,
        OutAirlineName3filter: null,
        OutDepartFrom3filter: null,
        OutArriveAt3filter: null,

        OutFlightNo4filter: null,
        OutAirlineName4filter: null,
        OutDepartFrom4filter: null,
        OutArriveAt4filter: null,
    }); // For Sorting And Pagination Purpose
    const [isExportExcel, setIsExportExcel] = useState(false);
    let Approvetosendagent = "Book Tickets";
    var history = useHistory();
    var token = localStorage.getItem("access_token");
    let _totalSize = totalSize;
    const UserName = localStorage.getItem("Username");

    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
    }, []);

    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                CompanyFilter: usrDetails.role === "MR" ? company : lazyState.current.filterCompany?.trim(),
                FullName: lazyState.current.name ? lazyState.current.name.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                City: lazyState.current.city ? lazyState.current.city.trim() : null,
                BoardingCity: lazyState.current.boardingCity ? lazyState.current.boardingCity.trim() : null,
                State: lazyState.current.state ? lazyState.current.state.trim() : null,
                country: lazyState.current.country ? lazyState.current.country.trim() : null,
                Email: lazyState.current.email ? lazyState.current.email.trim() : null,
                Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.code.trim() : null,
                Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
                ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
                FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,

                InBatchBooking: lazyState.current.inbatchfilter ? lazyState.current.inbatchfilter.trim() : null,
                OutBatchBooking: lazyState.current.outbatchfilter ? lazyState.current.outbatchfilter.trim() : null,

                InFlightNo1: lazyState.current.InFlightNo1filter ? lazyState.current.InFlightNo1filter.trim() : null,
                InAirlineName1: lazyState.current.InAirlineName1filter ? lazyState.current.InAirlineName1filter.trim() : null,
                InDepartFrom1: lazyState.current.InDepartFrom1filter ? lazyState.current.InDepartFrom1filter.trim() : null,
                InArriveAt1: lazyState.current.InArriveAt1filter ? lazyState.current.InArriveAt1filter.trim() : null,
                InFlightNo2: lazyState.current.InFlightNo2filter ? lazyState.current.InFlightNo2filter.trim() : null,
                InAirlineName2: lazyState.current.InAirlineName2filter ? lazyState.current.InAirlineName2filter.trim() : null,
                InDepartFrom2: lazyState.current.InDepartFrom2filter ? lazyState.current.InDepartFrom2filter.trim() : null,
                InArriveAt2: lazyState.current.InArriveAt2filter ? lazyState.current.InArriveAt2filter.trim() : null,
                InFlightNo3: lazyState.current.InFlightNo3filter ? lazyState.current.InFlightNo3filter.trim() : null,
                InAirlineName3: lazyState.current.InAirlineName3filter ? lazyState.current.InAirlineName3filter.trim() : null,
                InDepartFrom3: lazyState.current.InDepartFrom3filter ? lazyState.current.InDepartFrom3filter.trim() : null,
                InArriveAt3: lazyState.current.InArriveAt3filter ? lazyState.current.InArriveAt3filter.trim() : null,
                InFlightNo4: lazyState.current.InFlightNo4filter ? lazyState.current.InFlightNo4filter.trim() : null,
                InAirlineName4: lazyState.current.InAirlineName4filter ? lazyState.current.InAirlineName4filter.trim() : null,
                InDepartFrom4: lazyState.current.InDepartFrom4filter ? lazyState.current.InDepartFrom4filter.trim() : null,
                InArriveAt4: lazyState.current.InArriveAt4filter ? lazyState.current.InArriveAt4filter.trim() : null,

                OutFlightNo1: lazyState.current.OutFlightNo1filter ? lazyState.current.OutFlightNo1filter.trim() : null,
                OutAirlineName1: lazyState.current.OutAirlineName1filter ? lazyState.current.OutAirlineName1filter.trim() : null,
                OutDepartFrom1: lazyState.current.OutDepartFrom1filter ? lazyState.current.OutDepartFrom1filter.trim() : null,
                OutArriveAt1: lazyState.current.OutArriveAt1filter ? lazyState.current.OutArriveAt1filter.trim() : null,
                OutFlightNo2: lazyState.current.OutFlightNo2filter ? lazyState.current.OutFlightNo2filter.trim() : null,
                OutAirlineName2: lazyState.current.OutAirlineName2filter ? lazyState.current.OutAirlineName2filter.trim() : null,
                OutDepartFrom2: lazyState.current.OutDepartFrom2filter ? lazyState.current.OutDepartFrom2filter.trim() : null,
                OutArriveAt2: lazyState.current.OutArriveAt2filter ? lazyState.current.OutArriveAt2filter.trim() : null,
                OutFlightNo3: lazyState.current.OutFlightNo3filter ? lazyState.current.OutFlightNo3filter.trim() : null,
                OutAirlineName3: lazyState.current.OutAirlineName3filter ? lazyState.current.OutAirlineName3filter.trim() : null,
                OutDepartFrom3: lazyState.current.OutDepartFrom3filter ? lazyState.current.OutDepartFrom3filter.trim() : null,
                OutArriveAt3: lazyState.current.OutArriveAt3filter ? lazyState.current.OutArriveAt3filter.trim() : null,
                OutFlightNo4: lazyState.current.OutFlightNo4filter ? lazyState.current.OutFlightNo4filter.trim() : null,
                OutAirlineName4: lazyState.current.OutAirlineName4filter ? lazyState.current.OutAirlineName4filter.trim() : null,
                OutDepartFrom4: lazyState.current.OutDepartFrom4filter ? lazyState.current.OutDepartFrom4filter.trim() : null,
                OutArriveAt4: lazyState.current.OutArriveAt4filter ? lazyState.current.OutArriveAt4filter.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,

                registrationStatus: "APPROVED",
                ApproveToSendTravelAgentequal: "APPROVED",
                IsTicketBooked: false,
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        InBatch: dataObj.inBatchBooking,
                        OutBatch: dataObj.outBatchBooking,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        "Boarding City": dataObj.boardingcityname,
                        INF1FlightNo: dataObj.inFlightNo1,
                        INF1Airline: dataObj.inAirlineName1,
                        INF1DepartFrom: dataObj.inDepartFrom1,
                        INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF1ArriveAt: dataObj.inArriveAt1,
                        INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF2FlightNo: dataObj.inFlightNo2,
                        INF2Airline: dataObj.inAirlineName2,
                        INF2DepartFrom: dataObj.inDepartFrom2,
                        INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF2ArriveAt: dataObj.inArriveAt2,
                        INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF3FlightNo: dataObj.inFlightNo3,
                        INF3Airline: dataObj.inAirlineName3,
                        INF3DepartFrom: dataObj.inDepartFrom3,
                        INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF3ArriveAt: dataObj.inArriveAt3,
                        INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF4FlightNo: dataObj.inFlightNo4,
                        INF4Airline: dataObj.inAirlineName4,
                        INF4DepartFrom: dataObj.inDepartFrom4,
                        INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        INF4ArriveAt: dataObj.inArriveAt4,
                        INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1FlightNo: dataObj.outFlightNo1,
                        OUTF1Airline: dataObj.outAirlineName1,
                        OUTF1DepartFrom: dataObj.outDepartFrom1,
                        OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1ArriveAt: dataObj.outArriveAt1,
                        OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2FlightNo: dataObj.outFlightNo2,
                        OUTF2Airline: dataObj.outAirlineName2,
                        OUTF2DepartFrom: dataObj.outDepartFrom2,
                        OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2ArriveAt: dataObj.outArriveAt2,
                        OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3FlightNo: dataObj.outFlightNo3,
                        OUTF3Airline: dataObj.outAirlineName3,
                        OUTF3DepartFrom: dataObj.outDepartFrom3,
                        OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3ArriveAt: dataObj.outArriveAt3,
                        OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4FlightNo: dataObj.outFlightNo4,
                        OUTF4Airline: dataObj.outAirlineName4,
                        OUTF4DepartFrom: dataObj.outDepartFrom4,
                        OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4ArriveAt: dataObj.outArriveAt4,
                        OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",

                        // Hospital: dataObj.hospitalName,
                        // "Gender": dataObj.gender,
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            InBatch: dataObj.inBatchBooking,
                            OutBatch: dataObj.outBatchBooking,
                            Company: dataObj.company,
                            Email: dataObj.email,
                            MobileNo: dataObj.mobileNo,
                            "Boarding City": dataObj.boardingcityname,
                            INF1FlightNo: dataObj.inFlightNo1,
                            INF1Airline: dataObj.inAirlineName1,
                            INF1DepartFrom: dataObj.inDepartFrom1,
                            INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            INF1ArriveAt: dataObj.inArriveAt1,
                            INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            INF2FlightNo: dataObj.inFlightNo2,
                            INF2Airline: dataObj.inAirlineName2,
                            INF2DepartFrom: dataObj.inDepartFrom2,
                            INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            INF2ArriveAt: dataObj.inArriveAt2,
                            INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            INF3FlightNo: dataObj.inFlightNo3,
                            INF3Airline: dataObj.inAirlineName3,
                            INF3DepartFrom: dataObj.inDepartFrom3,
                            INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            INF3ArriveAt: dataObj.inArriveAt3,
                            INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            INF4FlightNo: dataObj.inFlightNo4,
                            INF4Airline: dataObj.inAirlineName4,
                            INF4DepartFrom: dataObj.inDepartFrom4,
                            INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            INF4ArriveAt: dataObj.inArriveAt4,
                            INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            OUTF1FlightNo: dataObj.outFlightNo1,
                            OUTF1Airline: dataObj.outAirlineName1,
                            OUTF1DepartFrom: dataObj.outDepartFrom1,
                            OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            OUTF1ArriveAt: dataObj.outArriveAt1,
                            OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                            OUTF2FlightNo: dataObj.outFlightNo2,
                            OUTF2Airline: dataObj.outAirlineName2,
                            OUTF2DepartFrom: dataObj.outDepartFrom2,
                            OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            OUTF2ArriveAt: dataObj.outArriveAt2,
                            OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                            OUTF3FlightNo: dataObj.outFlightNo3,
                            OUTF3Airline: dataObj.outAirlineName3,
                            OUTF3DepartFrom: dataObj.outDepartFrom3,
                            OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            OUTF3ArriveAt: dataObj.outArriveAt3,
                            OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                            OUTF4FlightNo: dataObj.outFlightNo4,
                            OUTF4Airline: dataObj.outAirlineName4,
                            OUTF4DepartFrom: dataObj.outDepartFrom4,
                            OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                            OUTF4ArriveAt: dataObj.outArriveAt4,
                            OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",

                            // Hospital: dataObj.hospitalName,
                            // "Gender": dataObj.gender,
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, Approvetosendagent);
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // For Sorting & Pagination
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setParticipantlist([]);
        setIsLoading(false);
        // };
    };

    //#region Method for filteration

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = e.target.value ? e.target.value : null;
                        break;
                    // case "hospitalname":
                    //     lazyState.current.HospitalNameFilter = e.target.value ? e.target.value : null
                    //     break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.email = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.city = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.state = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.country = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo1":
                        lazyState.current.InFlightNo1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName1":
                        lazyState.current.InAirlineName1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom1":
                        lazyState.current.InDepartFrom1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt1":
                        lazyState.current.InArriveAt1filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo2":
                        lazyState.current.InFlightNo2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName2":
                        lazyState.current.InAirlineName2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom2":
                        lazyState.current.InDepartFrom2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt2":
                        lazyState.current.InArriveAt2filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo3":
                        lazyState.current.InFlightNo3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName3":
                        lazyState.current.InAirlineName3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom3":
                        lazyState.current.InDepartFrom3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt3":
                        lazyState.current.InArriveAt3filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo4":
                        lazyState.current.InFlightNo4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName4":
                        lazyState.current.InAirlineName4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom4":
                        lazyState.current.InDepartFrom4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt4":
                        lazyState.current.InArriveAt4filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo1":
                        lazyState.current.OutFlightNo1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName1":
                        lazyState.current.OutAirlineName1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom1":
                        lazyState.current.OutDepartFrom1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt1":
                        lazyState.current.OutArriveAt1filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo2":
                        lazyState.current.OutFlightNo2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName2":
                        lazyState.current.OutAirlineName2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom2":
                        lazyState.current.OutDepartFrom2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt2":
                        lazyState.current.OutArriveAt2filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo3":
                        lazyState.current.OutFlightNo3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName3":
                        lazyState.current.OutAirlineName3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom3":
                        lazyState.current.OutDepartFrom3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt3":
                        lazyState.current.OutArriveAt3filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo4":
                        lazyState.current.OutFlightNo4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName4":
                        lazyState.current.OutAirlineName4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom4":
                        lazyState.current.OutDepartFrom4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt4":
                        lazyState.current.OutArriveAt4filter = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.name = null;
                        break;
                    case "boardingcity":
                        lazyState.current.boardingCity = null;
                        break;
                    // case "hospitalname":
                    //     lazyState.current.HospitalNameFilter = null
                    //     break;
                    case "company":
                        lazyState.current.filterCompany = null;
                        break;
                    case "Email":
                        lazyState.current.email = null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = null;
                        break;
                    case "city":
                        lazyState.current.city = null;
                        break;
                    case "state":
                        lazyState.current.state = null;
                        break;
                    case "country":
                        lazyState.current.country = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = null;
                        break;

                    case "InFlightNo1":
                        lazyState.current.InFlightNo1filter = null;
                        break;
                    case "InAirlineName1":
                        lazyState.current.InAirlineName1filter = null;
                        break;
                    case "InDepartFrom1":
                        lazyState.current.InDepartFrom1filter = null;
                        break;
                    case "InArriveAt1":
                        lazyState.current.InArriveAt1filter = null;
                        break;

                    case "InFlightNo2":
                        lazyState.current.InFlightNo2filter = null;
                        break;
                    case "InAirlineName2":
                        lazyState.current.InAirlineName2filter = null;
                        break;
                    case "InDepartFrom2":
                        lazyState.current.InDepartFrom2filter = null;
                        break;
                    case "InArriveAt2":
                        lazyState.current.InArriveAt2filter = null;
                        break;

                    case "InFlightNo3":
                        lazyState.current.InFlightNo3filter = null;
                        break;
                    case "InAirlineName3":
                        lazyState.current.InAirlineName3filter = null;
                        break;
                    case "InDepartFrom3":
                        lazyState.current.InDepartFrom3filter = null;
                        break;
                    case "InArriveAt3":
                        lazyState.current.InArriveAt3filter = null;
                        break;

                    case "InFlightNo4":
                        lazyState.current.InFlightNo4filter = null;
                        break;
                    case "InAirlineName4":
                        lazyState.current.InAirlineName4filter = null;
                        break;
                    case "InDepartFrom4":
                        lazyState.current.InDepartFrom4filter = null;
                        break;
                    case "InArriveAt4":
                        lazyState.current.InArriveAt4filter = null;
                        break;

                    case "OutFlightNo1":
                        lazyState.current.OutFlightNo1filter = null;
                        break;
                    case "OutAirlineName1":
                        lazyState.current.OutAirlineName1filter = null;
                        break;
                    case "OutDepartFrom1":
                        lazyState.current.OutDepartFrom1filter = null;
                        break;
                    case "OutArriveAt1":
                        lazyState.current.OutArriveAt1filter = null;
                        break;

                    case "OutFlightNo2":
                        lazyState.current.OutFlightNo2filter = null;
                        break;
                    case "OutAirlineName2":
                        lazyState.current.OutAirlineName2filter = null;
                        break;
                    case "OutDepartFrom2":
                        lazyState.current.OutDepartFrom2filter = null;
                        break;
                    case "OutArriveAt2":
                        lazyState.current.OutArriveAt2filter = null;
                        break;

                    case "OutFlightNo3":
                        lazyState.current.OutFlightNo3filter = null;
                        break;
                    case "OutAirlineName3":
                        lazyState.current.OutAirlineName3filter = null;
                        break;
                    case "OutDepartFrom3":
                        lazyState.current.OutDepartFrom3filter = null;
                        break;
                    case "OutArriveAt3":
                        lazyState.current.OutArriveAt3filter = null;
                        break;

                    case "OutFlightNo4":
                        lazyState.current.OutFlightNo4filter = null;
                        break;
                    case "OutAirlineName4":
                        lazyState.current.OutAirlineName4filter = null;
                        break;
                    case "OutDepartFrom4":
                        lazyState.current.OutDepartFrom4filter = null;
                        break;
                    case "OutArriveAt4":
                        lazyState.current.OutArriveAt4filter = null;
                        break;

                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.genderfilter}
                options={genderData.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.genderfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel);
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={boardingcityfilter} placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={Cityfilter} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={Statefilter} placeholder="Enter State" onChange={(e) => setFilterstate(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={Countryfilter} placeholder="Enter Country" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const ToEventCityFilterTemplate = () => {
        return (
            <Dropdown
                id="toeventcity"
                value={lazyState.current.Toeventcityfilter}
                options={Travelmode.Travelmode}
                optionLabel="name"
                placeholder="Select To Event City"
                showClear
                onChange={(e) => {
                    lazyState.current.Toeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { ToEventCity: e.value });
                    e.preventDefault();
                }}
            />
        );
    };

    const FromEventCityFilterTemplate = () => {
        return (
            <Dropdown
                id="fromeventcity"
                value={lazyState.current.Fromeventcityfilter}
                options={Travelmode.Travelmode}
                optionLabel="name"
                placeholder="Select From Event City"
                showClear
                onChange={(e) => {
                    lazyState.current.Fromeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { FromEventCity: e.value });
                    e.preventDefault();
                }}
            />
        );
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const InBatchFilterTemplate = () => {
        return <InputText id="inbatch" value={InBatchfilter} placeholder="Enter In Batch" onChange={(e) => setInBatchfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutBatchFilterTemplate = () => {
        return <InputText id="outbatch" value={OutBatchfilter} placeholder="Enter Out Batch" onChange={(e) => setOutBatchfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo1FilterTemplate = () => {
        return <InputText id="InFlightNo1" value={InFlightNo1filter} placeholder="Enter In Flight1" onChange={(e) => setInFlightNo1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName1FilterTemplate = () => {
        return <InputText id="InAirlineName1" value={InAirlineName1filter} placeholder="Enter In Airline1" onChange={(e) => setInAirlineName1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom1FilterTemplate = () => {
        return <InputText id="InDepartFrom1" value={InDepartFrom1filter} placeholder="Enter In Depart1" onChange={(e) => setInDepartFrom1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt1FilterTemplate = () => {
        return <InputText id="InArriveAt1" value={InArriveAt1filter} placeholder="Enter In Arrive1" onChange={(e) => setInArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo2FilterTemplate = () => {
        return <InputText id="InFlightNo2" value={InFlightNo2filter} placeholder="Enter In Flight2" onChange={(e) => setInFlightNo2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName2FilterTemplate = () => {
        return <InputText id="InAirlineName2" value={InAirlineName2filter} placeholder="Enter In Airline2" onChange={(e) => setInAirlineName2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom2FilterTemplate = () => {
        return <InputText id="InDepartFrom2" value={InDepartFrom2filter} placeholder="Enter In Depart2" onChange={(e) => setInDepartFrom2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt2FilterTemplate = () => {
        return <InputText id="InArriveAt2" value={InArriveAt2filter} placeholder="Enter In Arrive2" onChange={(e) => setInArriveAt2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo3FilterTemplate = () => {
        return <InputText id="InFlightNo3" value={InFlightNo3filter} placeholder="Enter In Flight3" onChange={(e) => setInFlightNo3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName3FilterTemplate = () => {
        return <InputText id="InAirlineName3" value={InAirlineName3filter} placeholder="Enter In Airline3" onChange={(e) => setInAirlineName3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom3FilterTemplate = () => {
        return <InputText id="InDepartFrom3" value={InDepartFrom3filter} placeholder="Enter In Depart3" onChange={(e) => setInDepartFrom3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt3FilterTemplate = () => {
        return <InputText id="InArriveAt3" value={InArriveAt3filter} placeholder="Enter In Arrive3" onChange={(e) => setInArriveAt3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo4FilterTemplate = () => {
        return <InputText id="InFlightNo4" value={InFlightNo4filter} placeholder="Enter In Flight4" onChange={(e) => setInFlightNo4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName4FilterTemplate = () => {
        return <InputText id="InAirlineName4" value={InAirlineName4filter} placeholder="Enter In Airline4" onChange={(e) => setInAirlineName4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom4FilterTemplate = () => {
        return <InputText id="InDepartFrom4" value={InDepartFrom4filter} placeholder="Enter In Depart4" onChange={(e) => setInDepartFrom4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt4FilterTemplate = () => {
        return <InputText id="InArriveAt4" value={InArriveAt4filter} placeholder="Enter In Arrive4" onChange={(e) => setInArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo1FilterTemplate = () => {
        return <InputText id="OutFlightNo1" value={OutFlightNo1filter} placeholder="Enter Out Flight1" onChange={(e) => setOutFlightNo1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName1FilterTemplate = () => {
        return <InputText id="OutAirlineName1" value={OutAirlineName1filter} placeholder="Enter Out Airline1" onChange={(e) => setOutAirlineName1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom1FilterTemplate = () => {
        return <InputText id="OutDepartFrom1" value={OutDepartFrom1filter} placeholder="Enter Out Depart1" onChange={(e) => setOutDepartFrom1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt1FilterTemplate = () => {
        return <InputText id="OutArriveAt1" value={OutArriveAt1filter} placeholder="Enter Out Arrive1" onChange={(e) => setOutArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo2FilterTemplate = () => {
        return <InputText id="OutFlightNo2" value={OutFlightNo2filter} placeholder="Enter Out Flight2" onChange={(e) => setOutFlightNo2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName2FilterTemplate = () => {
        return <InputText id="OutAirlineName2" value={OutAirlineName2filter} placeholder="Enter Out Airline2" onChange={(e) => setOutAirlineName2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom2FilterTemplate = () => {
        return <InputText id="OutDepartFrom2" value={OutDepartFrom2filter} placeholder="Enter Out Depart2" onChange={(e) => setOutDepartFrom2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt2FilterTemplate = () => {
        return <InputText id="OutArriveAt2" value={OutArriveAt2filter} placeholder="Enter Out Arrive2" onChange={(e) => setOutArriveAt2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo3FilterTemplate = () => {
        return <InputText id="OutFlightNo3" value={OutFlightNo3filter} placeholder="Enter Out Flight3" onChange={(e) => setOutFlightNo3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName3FilterTemplate = () => {
        return <InputText id="OutAirlineName3" value={OutAirlineName3filter} placeholder="Enter Out Airline3" onChange={(e) => setOutAirlineName3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom3FilterTemplate = () => {
        return <InputText id="OutDepartFrom3" value={OutDepartFrom3filter} placeholder="Enter Out Depart3" onChange={(e) => setOutDepartFrom3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt3FilterTemplate = () => {
        return <InputText id="OutArriveAt3" value={OutArriveAt3filter} placeholder="Enter Out Arrive3" onChange={(e) => setOutArriveAt3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo4FilterTemplate = () => {
        return <InputText id="OutFlightNo4" value={OutFlightNo4filter} placeholder="Enter Out Flight4" onChange={(e) => setOutFlightNo4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName4FilterTemplate = () => {
        return <InputText id="OutAirlineName4" value={OutAirlineName4filter} placeholder="Enter Out Airline4" onChange={(e) => setOutAirlineName4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom4FilterTemplate = () => {
        return <InputText id="OutDepartFrom4" value={OutDepartFrom4filter} placeholder="Enter Out Depart4" onChange={(e) => setOutDepartFrom4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt4FilterTemplate = () => {
        return <InputText id="OutArriveAt4" value={OutArriveAt4filter} placeholder="Enter Out Arrive4" onChange={(e) => setOutArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    //#endregion
    //#region Method for Column toggle
    const hidecolumns = [
        { field: "cityname", header: "City" },
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },
        { field: "travelmode_Inbound", header: "To Event City" },
        { field: "travelmode_Outbound", header: "From Event City" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    };

    const header = (
        <AccessControl allowedPermissions={["route:booktickets"]}>
            <div style={{ textAlign: "left", marginRight: "10px" }}>
                <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
            </div>
        </AccessControl>
    );

    const sortColumn = {
        cityname: "cityname",
        Statename: "Statename",
        Countryname: "Countryname",
        "To Event City": "Travelmode_Inbound",
        "From Event City": "Travelmode_Outbound",
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.name = null;
        setfullName(null);
        lazyState.current.email = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.boardingCity = null;
        setboardingcityfilter(null);
        lazyState.current.city = null;
        setCityfilter(null);
        lazyState.current.state = null;
        setStatefilter(null);
        lazyState.current.country = null;
        setCountryfilter(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.Designationfilter = null;

        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;
        setOutBatchfilter(null);
        lazyState.current.outbatchfilter = null;

        setInFlightNo1filter(null);
        setInAirlineName1filter(null);
        setInDepartFrom1filter(null);
        setInArriveAt1filter(null);
        lazyState.current.InFlightNo1filter = null;
        lazyState.current.InAirlineName1filter = null;
        lazyState.current.InDepartFrom1filter = null;
        lazyState.current.InArriveAt1filter = null;

        setInFlightNo2filter(null);
        setInAirlineName2filter(null);
        setInDepartFrom2filter(null);
        setInArriveAt2filter(null);
        lazyState.current.InFlightNo2filter = null;
        lazyState.current.InAirlineName2filter = null;
        lazyState.current.InDepartFrom2filter = null;
        lazyState.current.InArriveAt2filter = null;

        setInFlightNo3filter(null);
        setInAirlineName3filter(null);
        setInDepartFrom3filter(null);
        setInArriveAt3filter(null);
        lazyState.current.InFlightNo3filter = null;
        lazyState.current.InAirlineName3filter = null;
        lazyState.current.InDepartFrom3filter = null;
        lazyState.current.InArriveAt3filter = null;

        setInFlightNo4filter(null);
        setInAirlineName4filter(null);
        setInDepartFrom4filter(null);
        setInArriveAt4filter(null);
        lazyState.current.InFlightNo4filter = null;
        lazyState.current.InAirlineName4filter = null;
        lazyState.current.InDepartFrom4filter = null;
        lazyState.current.InArriveAt4filter = null;

        setOutFlightNo1filter(null);
        setOutAirlineName1filter(null);
        setOutDepartFrom1filter(null);
        setOutArriveAt1filter(null);
        lazyState.current.OutFlightNo1filter = null;
        lazyState.current.OutAirlineName1filter = null;
        lazyState.current.OutDepartFrom1filter = null;
        lazyState.current.OutArriveAt1filter = null;

        setOutFlightNo2filter(null);
        setOutAirlineName2filter(null);
        setOutDepartFrom2filter(null);
        setOutArriveAt2filter(null);
        lazyState.current.OutFlightNo2filter = null;
        lazyState.current.OutAirlineName2filter = null;
        lazyState.current.OutDepartFrom2filter = null;
        lazyState.current.OutArriveAt2filter = null;

        setOutFlightNo3filter(null);
        setOutAirlineName3filter(null);
        setOutDepartFrom3filter(null);
        setOutArriveAt3filter(null);
        lazyState.current.OutFlightNo3filter = null;
        lazyState.current.OutAirlineName3filter = null;
        lazyState.current.OutDepartFrom3filter = null;
        lazyState.current.OutArriveAt3filter = null;

        setOutFlightNo4filter(null);
        setOutAirlineName4filter(null);
        setOutDepartFrom4filter(null);
        setOutArriveAt4filter(null);
        lazyState.current.OutFlightNo4filter = null;
        lazyState.current.OutAirlineName4filter = null;
        lazyState.current.OutDepartFrom4filter = null;
        lazyState.current.OutArriveAt4filter = null;

        setSerialNumber(null);
        lazyState.current.serialnumber = null;

        getparticipantlist(isExportExcel, lazyState.current);
    };

    //Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["route:booktickets"]}>
                    <Button
                        disabled={
                            lazyState.current.name == null &&
                            lazyState.current.email == null &&
                            lazyState.current.filterHospitalName == null &&
                            lazyState.current.filterCompany == null &&
                            lazyState.current.filterMobileNo == null &&
                            lazyState.current.boardingCity == null &&
                            lazyState.current.country == null &&
                            lazyState.current.state == null &&
                            lazyState.current.city == null &&
                            lazyState.current.Designationfilter == null &&
                            lazyState.current.Genderfilter == null &&
                            lazyState.current.Toeventcityfilter == null &&
                            lazyState.current.Fromeventcityfilter == null &&
                            lazyState.current.inbatchfilter == null &&
                            lazyState.current.outbatchfilter == null &&
                            lazyState.current.InFlightNo1filter == null &&
                            lazyState.current.InAirlineName1filter == null &&
                            lazyState.current.InDepartFrom1filter == null &&
                            lazyState.current.InArriveAt1filter == null &&
                            lazyState.current.InFlightNo2filter == null &&
                            lazyState.current.InAirlineName2filter == null &&
                            lazyState.current.InDepartFrom2filter == null &&
                            lazyState.current.InArriveAt2filter == null &&
                            lazyState.current.InFlightNo3filter == null &&
                            lazyState.current.InAirlineName3filter == null &&
                            lazyState.current.InDepartFrom3filter == null &&
                            lazyState.current.InArriveAt3filter == null &&
                            lazyState.current.InFlightNo4filter == null &&
                            lazyState.current.InAirlineName4filter == null &&
                            lazyState.current.InDepartFrom4filter == null &&
                            lazyState.current.InArriveAt4filter == null &&
                            lazyState.current.OutFlightNo1filter == null &&
                            lazyState.current.OutAirlineName1filter == null &&
                            lazyState.current.OutDepartFrom1filter == null &&
                            lazyState.current.OutArriveAt1filter == null &&
                            lazyState.current.OutFlightNo2filter == null &&
                            lazyState.current.OutAirlineName2filter == null &&
                            lazyState.current.OutDepartFrom2filter == null &&
                            lazyState.current.OutArriveAt2filter == null &&
                            lazyState.current.OutFlightNo3filter == null &&
                            lazyState.current.OutAirlineName3filter == null &&
                            lazyState.current.OutDepartFrom3filter == null &&
                            lazyState.current.OutArriveAt3filter == null &&
                            lazyState.current.OutFlightNo4filter == null &&
                            lazyState.current.OutAirlineName4filter == null &&
                            lazyState.current.OutDepartFrom4filter == null &&
                            lazyState.current.serialnumber == null &&
                            lazyState.current.OutArriveAt4filter == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:booktickets"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            exportExcel();
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                headerStyle={{ width: "15%", minWidth: "15rem" }}
                sortField={sortColumn[col.header]}
                filter
                showFilterMenu={false}
                filterElement={col.header == "City" ? CityFilterTemplate : col.header == "State" ? StateFilterTemplate : col.header == "To Event City" ? ToEventCityFilterTemplate : col.header == "From Event City" ? FromEventCityFilterTemplate : CountryFilterTemplate}
            />
        );
    });
    //#endregion

    const UpdateInboundOutbound = () => {
        setSubmitted(true);
        if (totalSize > 0) {
            setIsLoading(true);
            axios
                .put("/api/Participant/updateparticipantbymultiple", {
                    id: id.toString(),
                    infare: infare ? infare : null,
                    outfare: outfare ? outfare : null,
                    IsTicketBooked: true,
                    ModifiedBy: UserName,
                })
                .then((res) => {
                    setActionDisable(true);
                    setinfare(null);
                    setoutfare(null);
                    getparticipantlist(isExportExcel, lazyState.current);
                    // document.documentElement.style.setProperty('overflow', 'auto');
                    setTicketdialog(false);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                });
        }
    };

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        id.splice(0, id.length);
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    // On Add New City
    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setmatierialfile([]);
        setSubmitted(false);
        // setTotalSize(0);
        setTicketdialog(true);
    };

    const ViewAttendees = (rowData) => {
        return (
            <AccessControl allowedPermissions={["view:booktickets"]}>
                <Link
                    className="linkcolors"
                    id="viewattendee"
                    to="#"
                    onClick={(e) => {
                        history.push("/home/viewparticipant/" + rowData.id);
                    }}
                >
                    {rowData.fullName}
                </Link>
            </AccessControl>
        );
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <>
                    <h4 className="headerfont m-auto mr-2">Count : {totalRecords}</h4>
                </>
                <AccessControl allowedPermissions={["upload:booktickets"]}>
                    <div className="my-2">
                        <Button disabled={ActionDisable} label="Upload Air Tickets" icon="fa fa-upload" className="mr-2" onClick={openNew} outlined />
                    </div>
                </AccessControl>
            </div>
        );
    };

    const TicketUpload = () => {
        setSubmitted(true);
        if (totalSize > 0) {
            const formData1 = new FormData();
            materialFile.forEach((file) => {
                formData1.append("DocumentRefrence", file);
            });
            const mData = JSON.stringify(id);
            formData1.append("data", mData);
            const headers = {
                "Accept-Type": "application/json",
                Authorization: "Bearer " + token,
            };
            axios
                .post("/api/Participant/Ticketupload", formData1, {
                    headers: headers,
                    credentials: "include",
                })
                .then((Response) => {
                    materialFile.splice(0, materialFile.length);
                    id.splice(0, id.length);
                    setTicketdialog(false);
                    setinfare(null);
                    setoutfare(null);
                    setActionDisable(true);
                    setselecteddata(null);
                    toast.current.show({ severity: "success", summary: "success", detail: "Ticket Uploaded Successfully", life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                    getparticipantlist(isExportExcel, lazyState.current);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                });
        }
    };

    const onclosedialog = () => {
        materialFile.splice(0, materialFile.length);
        setTotalSize(0);
        setTicketdialog(false);
        setinfare(null);
        setoutfare(null);
    };

    // Modals Button To Add City
    const ticketDialogFooter = (
        <div className="mt-0">
            <Button
                label="Submit"
                icon="fa fa-check"
                onClick={(e) => {
                    TicketUpload(e);
                    UpdateInboundOutbound(e);
                }}
                outlined
            />
            <Button
                label="Cancel"
                icon="fa fa-times"
                onClick={(e) => {
                    //  document.documentElement.style.setProperty('overflow', 'auto');
                    onclosedialog();
                }}
                outlined
            />
        </div>
    );

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "BookTickets" } })}>
                    {" "}
                    <span className="fa fa-solid fa-eye" id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };

    const onTemplateSelect = (e) => {
        for (let dataObj of e.files) {
            if (files.filter((x) => x.name == dataObj.name)?.length <= 0) {
                if (dataObj.type === "application/pdf") {
                    if (_totalSize + dataObj.size < 25000000) {
                        _totalSize += dataObj.size;
                        files.push(dataObj);
                    } else {
                        toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select File Upto 25Mb Only", life: 3000 });
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Only Pdf Format File", life: 3000 });
                }
            } else {
                toast.current.show({ severity: "error", summary: "Warning", detail: "You Cannot Select Same Flight Twice", life: 3000 });
            }
            setTotalSize(_totalSize);
            setmatierialfile(files);
        }
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded" });
    };

    const onTemplateRemove = (file, callback) => {
        var fileSizeAfterRemove = totalSize - file.size;
        setTotalSize(fileSizeAfterRemove);
        var indexofFileToRemove = files.indexOf(file);
        files.splice(indexofFileToRemove, 1);
        setmatierialfile(files);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : "0 B";
        return (
            <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
                {chooseButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 25 MB</span>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        if (files.filter((x) => x.name == file.name)?.length > 0) {
            return (
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: "70%" }}>
                        <span className="flex flex-column text-left ">
                            {file.name}
                            <small>{new Date().toLocaleDateString()}</small>
                        </span>
                    </div>
                    <Tag value={props.formatSize} severity="warning" />
                    <Button type="button" icon="fa fa-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto " style={{ width: "2.5rem", height: "2.5rem" }} onClick={() => onTemplateRemove(file, props.onRemove)} />
                </div>
            );
        }
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ marginTop: "2rem" }}>
                <i className="fa fa-upload " style={{ height: "3rem", fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
                <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
                    Upload your tickets here
                </span>
            </div>
        );
    };

    return (
        <AccessControl allowedPermissions={["route:booktickets"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-ticket mr-2"></i>Book Tickets
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                    size="small"
                                    lazy
                                    rows={lazyState.current.rows}
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    selectionMode={"checkbox"}
                                    selection={selecteddata}
                                    onSelectionChange={(e) => setselecteddata(e.value)}
                                    onAllRowsSelect={onAllRowSelect}
                                    onRowSelect={onRowSelect}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    filterDisplay={"row"}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    dataKey="id"
                                    className="datatable-responsive"
                                    responsiveLayout="scroll"
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column frozen selectionMode="multiple" headerStyle={{ width: "0%", minWidth: "1rem" }}></Column>
                                    
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column frozen body={ViewAttendees} field="fullName" sortField="FullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>

                                    <Column field="inBatchBooking" header="In Batch" sortField="InBatchBooking" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={InBatchFilterTemplate}></Column>

                                    <Column field="outBatchBooking" sortField="OutBatchBooking" sortable header="Out Batch" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={OutBatchFilterTemplate}></Column>

                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton></Column>
                                    <Column body={ButtonMailto} header="Email" sortField="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" sortable header="Mobile No." headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    <Column field="boardingcityname" header="Boarding City" sortField="boardingcityname" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate}></Column>
                                    {/* <Column field="gender" header="Gender" sortField="Gender" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={GenderFilterTemplate}></Column> */}
                                    {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column> */}
                                    {hidecolumnComponents}
                                    <Column field="inFlightNo1" sortField="InFlightNo1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Flight No" filter showFilterMenu={false} filterElement={InFlightNo1FilterTemplate}></Column>
                                    <Column field="inAirlineName1" sortField="InAirlineName1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Airline" filter showFilterMenu={false} filterElement={InAirlineName1FilterTemplate}></Column>
                                    <Column field="inDepartFrom1" sortField="InDepartFrom1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom1FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime1"
                                        sortField="InDepartureDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime1 ? moment(rowData.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt1" sortField="InArriveAt1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt1FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime1"
                                        sortField="InArrivalDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime1 ? moment(rowData.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo2" sortField="InFlightNo2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Flight No" filter showFilterMenu={false} filterElement={InFlightNo2FilterTemplate}></Column>
                                    <Column field="inAirlineName2" sortField="InAirlineName2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Airline" filter showFilterMenu={false} filterElement={InAirlineName2FilterTemplate}></Column>
                                    <Column field="inDepartFrom2" sortField="InDepartFrom2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom2FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime2"
                                        sortField="InDepartureDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime2 ? moment(rowData.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt2" sortField="InArriveAt2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt2FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime2"
                                        sortField="InArrivalDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime2 ? moment(rowData.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo3" sortField="InFlightNo3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Flight No" filter showFilterMenu={false} filterElement={InFlightNo3FilterTemplate}></Column>
                                    <Column field="inAirlineName3" sortField="InAirlineName3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Airline" filter showFilterMenu={false} filterElement={InAirlineName3FilterTemplate}></Column>
                                    <Column field="inDepartFrom3" sortField="InDepartFrom3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom3FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime3"
                                        sortField="InDepartureDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime3 ? moment(rowData.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt3" sortField="InArriveAt3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt3FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime3"
                                        sortField="InArrivalDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime3 ? moment(rowData.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo4" sortField="InFlightNo4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Flight No" filter showFilterMenu={false} filterElement={InFlightNo4FilterTemplate}></Column>
                                    <Column field="inAirlineName4" sortField="InAirlineName4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Airline" filter showFilterMenu={false} filterElement={InAirlineName4FilterTemplate}></Column>
                                    <Column field="inDepartFrom4" sortField="InDepartFrom4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom4FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime4"
                                        sortField="InDepartureDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime4 ? moment(rowData.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt4" sortField="InArriveAt4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt4FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime4"
                                        sortField="InArrivalDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime4 ? moment(rowData.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo1" sortField="OutFlightNo1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo1FilterTemplate}></Column>
                                    <Column field="outAirlineName1" sortField="OutAirlineName1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Airline" filter showFilterMenu={false} filterElement={OutAirlineName1FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom1"
                                        sortField="OutDepartFrom1"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom1FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime1"
                                        sortField="OutDepartureDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime1 ? moment(rowData.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt1" sortField="OutArriveAt1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt1FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime1"
                                        sortField="OutArrivalDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime1 ? moment(rowData.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo2" sortField="OutFlightNo2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo2FilterTemplate}></Column>
                                    <Column field="outAirlineName2" sortField="OutAirlineName2" sortable headerStyle={{ width: "20%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Airline" filter showFilterMenu={false} filterElement={OutAirlineName2FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom2"
                                        sortField="OutDepartFrom2"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom2FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime2"
                                        sortField="OutDepartureDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime2 ? moment(rowData.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt2" sortField="OutArriveAt2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt2FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime2"
                                        sortField="OutArrivalDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime2 ? moment(rowData.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo3" sortField="OutFlightNo3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo3FilterTemplate}></Column>
                                    <Column field="outAirlineName3" sortField="OutAirlineName3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Airline" filter showFilterMenu={false} filterElement={OutAirlineName3FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom3"
                                        sortField="OutDepartFrom3"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom3FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime3"
                                        sortField="OutDepartureDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime3 ? moment(rowData.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt3" sortField="OutArriveAt3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt3FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime3"
                                        sortField="OutArrivalDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime3 ? moment(rowData.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo4" sortField="OutFlightNo4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo4FilterTemplate}></Column>
                                    <Column field="outAirlineName4" sortField="OutAirlineName4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Airline" filter showFilterMenu={false} filterElement={OutAirlineName4FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom4"
                                        sortField="OutDepartFrom4"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom4FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime4"
                                        sortField="OutDepartureDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime4 ? moment(rowData.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt4" sortField="OutArriveAt4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt4FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime4"
                                        sortField="OutArrivalDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime4 ? moment(rowData.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Arrival DateTime"
                                    ></Column>
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>

                                <Dialog
                                    visible={ticketDialog}
                                    draggable={false}
                                    style={{ width: "500px" }}
                                    header={"Upload Air Tickets"}
                                    modal
                                    className="p-fluid"
                                    footer={ticketDialogFooter}
                                    onHide={(e) => {
                                        // document.documentElement.style.setProperty('overflow', 'auto');
                                        onclosedialog();
                                    }}
                                >
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label id="label" htmlFor="Inbound Fare :">
                                                Inbound Fare
                                            </label>
                                            <InputNumber value={infare} id="Inbound Fare :" autoFocus placeholder="Enter Inbound Fare" onChange={(e) => setinfare(e.value)} />
                                        </div>
                                        <div className="field col">
                                            <label id="label" htmlFor="Outbound Fare :">
                                                Outbound Fare
                                            </label>
                                            <InputNumber value={outfare} id="Outbound Fare :" placeholder="Enter Outbound Fare" onChange={(e) => setoutfare(e.value)} />
                                        </div>
                                    </div>
                                    <div className="field mb-0">
                                        <label htmlFor="name" id="label">
                                            Choose Files
                                        </label>
                                        <label id="Mandatoryfield">*</label>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            multiple
                                            name="demo[]"
                                            url="/api/upload"
                                            accept="application/pdf"
                                            maxFileSize={25000000}
                                            onUpload={onTemplateUpload}
                                            onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            emptyTemplate={emptyTemplate}
                                        />
                                        {submitted && totalSize < 1 && <div className="p-invalid">Please select atleast one file.</div>}
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};
export default BookTickets;
