import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory, useLocation } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import * as Designations from "../../enums/Designations";
import * as FlightBookingStatus from "../../enums/BookingStatus";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";

const ArrivalSummary = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [travelSummary, setTravelSummary] = useState();
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterInBatchBooking, setFilterInBatchBooking] = useState(null);
    const [filterOutBatchBooking, setFilterOutBatchBooking] = useState(null);
    const [filterZone, setFilterZone] = useState(null);
    const [filterTravelRemarks, setFilterTravelRemarks] = useState(null);
    const [filterArriveAt, setFilterArriveAt] = useState(null);
    const [filterDepartFrom, setFilterDepartFrom] = useState(null);
    const [filterFlightNo, setFilterFlightNo] = useState(null);
    const [filterReturnFlightNo, setFilterReturnFlightNo] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);

    // Flags
    const [enableFilter, setEnableFilter] = useState(false);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var company = localStorage.getItem("Company");
    const CompanyId = localStorage.getItem("CompanyId");
    let TravelSummary = "";
    var usrDetails = userDetails();

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        filterZone: null,
        filterCity: null,
        filterInFlightBookingStatus: null,
        filterOutFlightBookingStatus: null,
        filterInBatchBooking: null,
        filterOutBatchBooking: null,
        filterTravelRemarks: null,
        fullName: null,
        filterHospitalName: null,
        filterCompany: null,
        filterDesignation: null,
        filterEmail: null,
        filterMobileNo: null,
        filterDepartFrom: null,
        filterArriveAt: null,
        flightNo: null,
        serialnumber: null,
        returnflightno: null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
    }, []);

    // - All Api

    //#region Common Methods
    // Get All participant
    const getparticipantlist = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("api/Participant/ArrivalDepartureSummary", {
                PaginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder.toString(),
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany.trim() : null,
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                Designation: lazyState.current.filterDesignation ? lazyState.current.filterDesignation.code.trim() : null,
                Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                FlightNo: lazyState.current.flightNo ? lazyState.current.flightNo.trim() : null,
                ArrivalReturnFlightNo: lazyState.current.returnflightno ? lazyState.current.returnflightno.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
                // BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity : null,
                // City: lazyState.current.filterCity ? lazyState.current.filterCity : null,
                // Zone: lazyState.current.filterZone ? lazyState.current.filterZone : null,
                //TricoConfirmed: lazyState.current.filterTricoConfirmed ? lazyState.current.filterTricoConfirmed.code : null,
                OutFlightBookingStatus: window.location.href.includes("departuresummary") ? "Approved" : lazyState.current.filterOutFlightBookingStatus ? lazyState.current.filterOutFlightBookingStatus.code.trim() : null,
                InFlightBookingStatus: window.location.href.includes("arrivalsummary") ? "Approved" : lazyState.current.filterInFlightBookingStatus ? lazyState.current.filterInFlightBookingStatus.code.trim() : null,
                OutBatchBooking: lazyState.current.filterOutBatchBooking ? lazyState.current.filterOutBatchBooking.trim() : null,
                InBatchBooking: lazyState.current.filterInBatchBooking ? lazyState.current.filterInBatchBooking.trim() : null,
                TravelRemarks: lazyState.current.filterTravelRemarks ? lazyState.current.filterTravelRemarks : null,
                Travel_mode_Outbound: window.location.href.includes("departuresummary") ? "By Air" : null,
                Travel_mode_Inbound: window.location.href.includes("arrivalsummary") ? "By Air" : null,
                DepartFrom: lazyState.current.filterDepartFrom ? lazyState.current.filterDepartFrom.trim() : null,
                ArriveAt: lazyState.current.filterArriveAt ? lazyState.current.filterArriveAt.trim() : null,
                ArrivalSummary: window.location.href.includes("arrivalsummary") ? "true" : null,
                DepartureSummary: window.location.href.includes("departuresummary") ? "true" : null,
            })
            .then((Response) => {
                console.log(Response.data.data);
                if (window.location.href.includes("departuresummary")) {
                    setTravelSummary("Departure Summary");
                    TravelSummary = "Departure Summary";
                }
                if (window.location.href.includes("arrivalsummary")) {
                    setTravelSummary("Arrival Summary");
                    TravelSummary = "Arrival Summary";
                }
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                switch (TravelSummary) {
                    case "Departure Summary":
                        for (let dataObj of Response.data.data) {
                            // Pass body data in excelsheets.
                            let allExcelData;
                            let excelcolumnData = {
                                Id: dataObj.serialNumber,
                                Name: dataObj.fullName,
                                FlightNo: dataObj.flightNo,
                                ReturnFlightNo: dataObj.returnFlightNo,
                                MobileNo: dataObj.mobileNo,
                                // Email: dataObj.email,
                                // Company: dataObj.company,
                                DepartFrom: dataObj.departFrom,
                                ArriveAt: dataObj.arriveAt,
                                DepartOnTime: dataObj.departureDateTime ? moment(dataObj.departureDateTime).format("DD/MM/YYYY HH:mm") : "",
                                ArriveAtTime: dataObj.arrivalDateTime ? moment(dataObj.arrivalDateTime).format("DD/MM/YYYY HH:mm") : "",
                                // Hospital: dataObj.hospitalName,
                                // Designtaion: dataObj.designation,
                                // "MobileNo": dataObj.mobileNo,
                                // "Boarding City": dataObj.boardingcityname
                            };
                            if (selectedColumns.length > 0) {
                                for (let selectedColumn of selectedColumns) {
                                    let fieldName = selectedColumn.field;
                                    let headerName = selectedColumn.header;
                                    excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                                }
                                tempData.push(excelcolumnData);
                            } else {
                                allExcelData = {
                                    Id: dataObj.serialNumber,
                                    Name: dataObj.fullName,
                                    FlightNo: dataObj.flightNo,
                                    ReturnFlightNo: dataObj.returnFlightNo,
                                    MobileNo: dataObj.mobileNo,
                                    DepartFrom: dataObj.departFrom,
                                    ArriveAt: dataObj.arriveAt,
                                    DepartOnTime: dataObj.departureDateTime ? moment(dataObj.departureDateTime).format("DD/MM/YYYY HH:mm") : "",
                                    ArriveAtTime: dataObj.arrivalDateTime ? moment(dataObj.arrivalDateTime).format("DD/MM/YYYY HH:mm") : "",
                                    // Email: dataObj.email,
                                    // Company: dataObj.company,
                                    // Hospital: dataObj.hospitalName,
                                    // Designtaion: dataObj.designation,
                                    // "MobileNo": dataObj.mobileNo,
                                    // "Boarding City": dataObj.boardingcityname
                                };
                                tempData.push(allExcelData);
                            }
                        }
                        if (isExportExcel == true) {
                            exportData(tempData, travelSummary);
                            setIsLoading(false);
                            return false;
                        }
                        setTotalRecords(Response.data.totalRecords);
                        const ModifyParticipantListData = Response.data.data.map((TricoConfirmedData) => ({
                            ...TricoConfirmedData,
                            //tricoConfirmed: TricoConfirmedData.tricoConfirmed === true ? "Yes" : TricoConfirmedData.tricoConfirmed === false ? 'No' : ''
                        }));
                        setParticipantlist(ModifyParticipantListData);
                        break;

                    case "Arrival Summary":
                        for (let dataObj of Response.data.data) {
                            // Pass body data in excelsheets.
                            let allExcelData;
                            let excelcolumnData = {
                                Id: dataObj.serialNumber,
                                Name: dataObj.fullName,
                                FlightNo: dataObj.flightNo,
                                ReturnFlightNo: dataObj.returnFlightNo,
                                MobileNo: dataObj.mobileNo,
                                DepartFrom: dataObj.departFrom,
                                ArriveAt: dataObj.arriveAt,
                                DepartOnTime: dataObj.departureDateTime ? moment(dataObj.departureDateTime).format("DD/MM/YYYY HH:mm") : "",
                                ArriveAtTime: dataObj.arrivalDateTime ? moment(dataObj.arrivalDateTime).format("DD/MM/YYYY HH:mm") : "",
                                // Email: dataObj.email,
                                // Company: dataObj.company,
                                // Hospital: dataObj.hospitalName,
                                // Designtaion: dataObj.designation,
                                // "Mobile No": dataObj.mobileNo,
                            };
                            if (selectedColumns.length > 0) {
                                for (let selectedColumn of selectedColumns) {
                                    let fieldName = selectedColumn.field;
                                    let headerName = selectedColumn.header;
                                    excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                                }
                                tempData.push(excelcolumnData);
                            } else {
                                allExcelData = {
                                    Id: dataObj.serialNumber,
                                    Name: dataObj.fullName,
                                    FlightNo: dataObj.flightNo,
                                    ReturnFlightNo: dataObj.returnFlightNo,
                                    MobileNo: dataObj.mobileNo,
                                    DepartFrom: dataObj.departFrom,
                                    ArriveAt: dataObj.arriveAt,
                                    DepartOnTime: dataObj.departureDateTime ? moment(dataObj.departureDateTime).format("DD/MM/YYYY HH:mm") : "",
                                    ArriveAtTime: dataObj.arrivalDateTime ? moment(dataObj.arrivalDateTime).format("DD/MM/YYYY HH:mm") : "",
                                    // Email: dataObj.email,
                                    // Company: dataObj.company,
                                    // Hospital: dataObj.hospitalName,
                                    // Designtaion: dataObj.designation,
                                    // "Mobile No": dataObj.mobileNo,
                                };
                                tempData.push(allExcelData);
                            }
                        }
                        if (isExportExcel == true) {
                            exportData(tempData, travelSummary);
                            setIsLoading(false);
                            return false;
                        }
                        setTotalRecords(Response.data.totalRecords);
                        const ModifyParticipantList = Response.data.data.map((TricoConfirmedData) => ({
                            ...TricoConfirmedData,
                            //tricoConfirmed: TricoConfirmedData.tricoConfirmed === true ? "Yes" : TricoConfirmedData.tricoConfirmed === false ? 'No' : ''
                        }));
                        setParticipantlist(ModifyParticipantList);
                        break;
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterDepartFrom = null;
        setFilterDepartFrom(null);
        lazyState.current.filterArriveAt = null;
        setFilterArriveAt(null);
        lazyState.current.filterDesignation = null;
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.filterZone = null;
        setFilterZone(null);
        lazyState.current.filterOutFlightBookingStatus = null;
        lazyState.current.filterInFlightBookingStatus = null;
        lazyState.current.filterOutBatchBooking = null;
        setFilterOutBatchBooking(null);
        lazyState.current.filterInBatchBooking = null;
        setFilterInBatchBooking(null);
        lazyState.current.filterTravelRemarks = null;
        setFilterTravelRemarks(null);
        lazyState.current.flightNo = null;
        setFilterFlightNo(null);

        lazyState.current.returnflightno = null;
        setFilterReturnFlightNo(null);

        lazyState.current.serialnumber = null;
        setSerialNumber(null);
        getparticipantlist(false, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:participant"]}>
                    <Button
                        disabled={
                            lazyState.current.fullName == null &&
                            lazyState.current.filterHospitalName == null &&
                            lazyState.current.filterCompany == null &&
                            lazyState.current.filterDepartFrom == null &&
                            lazyState.current.filterArriveAt == null &&
                            lazyState.current.filterDesignation == null &&
                            lazyState.current.filterEmail == null &&
                            lazyState.current.filterMobileNo == null &&
                            lazyState.current.filterCity == null &&
                            lazyState.current.filterOutFlightBookingStatus == null &&
                            lazyState.current.filterInFlightBookingStatus == null &&
                            lazyState.current.filterOutBatchBooking == null &&
                            lazyState.current.flightNo == null &&
                            lazyState.current.returnflightno == null &&
                            lazyState.current.filterInBatchBooking == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="p-button-outlined mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:participant"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        onClick={() => {
                            getparticipantlist(true);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };
    //#endregion

    //#region Method for filteration
    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Designation":
                        lazyState.current.filterDesignation = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "DepartFrom":
                        lazyState.current.filterDepartFrom = e.target.value ? e.target.value : null;
                        break;
                    case "ArriveAt":
                        lazyState.current.filterArriveAt = e.target.value ? e.target.value : null;
                        break;
                    // case "city":
                    //     lazyState.current.filterCity = e.target.value ? e.target.value : null
                    //     break;
                    // case "tricoConfirmed":
                    //     lazyState.current.filterTricoConfirmed = e.target.value ? e.target.value : null
                    //     break;
                    case "outFlightBookingStatus":
                        lazyState.current.filterOutFlightBookingStatus = e.target.value ? e.target.value : null;
                        break;
                    case "inFlightBookingStatus":
                        lazyState.current.filterInFlightBookingStatus = e.target.value ? e.target.value : null;
                        break;
                    case "inBatchBooking":
                        lazyState.current.filterInBatchBooking = e.target.value ? e.target.value : null;
                        break;
                    case "outBatchBooking":
                        lazyState.current.filterOutBatchBooking = e.target.value ? e.target.value : null;
                        break;
                    case "flightno":
                        lazyState.current.flightNo = e.target.value ? e.target.value : null;
                        break;
                    case "returnflightno":
                        lazyState.current.returnflightno = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                    // case "travelRemarks":
                    //     lazyState.current.filterTravelRemarks = e.target.value ? e.target.value : null
                    //     break;
                    // case "zone":
                    //     lazyState.current.filterZone = e.target.value ? e.target.value : null
                    //     break;
                    // case "boardingcity":
                    //     lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null
                    //     break;
                }
                getparticipantlist(false, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = null;
                        break;
                    case "Designation":
                        lazyState.current.filterDesignation = null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = null;
                        break;
                    case "DepartFrom":
                        lazyState.current.filterDepartFrom = null;
                        break;
                    case "ArriveAt":
                        lazyState.current.filterArriveAt = null;
                        break;
                    // case "city":
                    //     lazyState.current.filterCity = null
                    //     break;
                    // case "tricoConfirmed":
                    //     lazyState.current.filterTricoConfirmed = null
                    //     break;
                    case "outFlightBookingStatus":
                        lazyState.current.filterOutFlightBookingStatus = null;
                        break;
                    case "inFlightBookingStatus":
                        lazyState.current.filterInFlightBookingStatus = null;
                        break;
                    case "inBatchBooking":
                        lazyState.current.filterInBatchBooking = null;
                        break;
                    case "outBatchBooking":
                        lazyState.current.filterOutBatchBooking = null;
                        break;
                    case "flightno":
                        lazyState.current.flightNo = null;
                        break;
                    case "returnflightno":
                        lazyState.current.returnflightno = null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                    // case "travelRemarks":
                    //     lazyState.current.filterTravelRemarks = null
                    //     break;
                    // case "zone":
                    //     lazyState.current.filterZone = null
                    //     break;
                    // case "boardingcity":
                    //     lazyState.current.filterBoardingCity = null
                    //     break;
                }
                getparticipantlist(false, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterDesignation}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterDesignation = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const InFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterInFlightBookingStatus}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterInFlightBookingStatus = e.target.value ? e.target.value : null;
                    getparticipantlist({ InFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="Select InFlight Booking Status"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const OutFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterOutFlightBookingStatus}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterOutFlightBookingStatus = e.target.value ? e.target.value : null;
                    getparticipantlist({ OutFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="Select OutFlight Booking Status"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const InBatchBookingFilterTemplate = () => {
        return <InputText id="inBatchBooking" value={filterInBatchBooking} placeholder="Enter InBatch Booking" onChange={(e) => setFilterInBatchBooking(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const ArriveAtFilterTemplate = () => {
        return <InputText id="ArriveAt" value={filterArriveAt} placeholder="Enter Arrive At" onChange={(e) => setFilterArriveAt(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const DepartFromFilterTemplate = () => {
        return <InputText id="DepartFrom" value={filterDepartFrom} placeholder="Enter Depart From" onChange={(e) => setFilterDepartFrom(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const OutBatchBookingFilterTemplate = () => {
        return <InputText id="outBatchBooking" value={filterOutBatchBooking} placeholder="Enter OutBatch Booking" onChange={(e) => setFilterOutBatchBooking(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={filterCity} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const ZoneFilterTemplate = () => {
        return <InputText id="zone" value={filterZone} placeholder="Enter Zone" onChange={(e) => setFilterZone(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    // const TricoConfirmedFilterTemplate = () => {
    //     return (
    //         <Dropdown value={lazyState.current.filterTricoConfirmed} options={TricoConfirmedEnum} optionLabel="name" showClear onChange={(e) => { lazyState.current.filterTricoConfirmed = (e.target.value ? e.target.value : null); getparticipantlist(); e.preventDefault() }} placeholder="Select Trico Confirmed" className="p-column-filter" style={{ minWidth: '12rem' }} />
    //     );
    // };

    const TravelRemarksFilterTemplate = () => {
        return <InputText id="travelremark" value={filterTravelRemarks} placeholder="Enter travel Remark" onChange={(e) => setFilterTravelRemarks(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={filterBoardingCity} filterBoardingCity placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };
    const FlightNoFilterTemplate = () => {
        return <InputText id="flightno" value={filterFlightNo} placeholder="Enter FlightNo" onChange={(e) => setFilterFlightNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "150px" }} />;
    };
    const ReturnFlightNoFilterTemplate = () => {
        return <InputText id="returnflightno" value={filterReturnFlightNo} placeholder="Enter FlightNo" onChange={(e) => setFilterReturnFlightNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "150px" }} />;
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    // const FromEventCityTemplate = () => {
    //     return (
    //         <Dropdown value={lazyState.current.Fromeventcityfilter} options={Travelmodes.Travelmode} optionLabel="name" showClear onChange={(e) => { lazyState.current.Fromeventcityfilter = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { FromEventCity: e.value }); e.preventDefault() }} placeholder="Select TravelMode" className="p-column-filter" style={{ minWidth: '12rem' }} />
    //     );
    // };
    //#endregion

    //#region for sorting and Pagination

    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };
    //#endregion

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    // Move to view attendee
    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "cityname", header: "City"},
        // { field: "zone", header: "Zone"},
        // { field: "tricoConfirmed", header: "Trico Confirmed"},
        // { field: "boardingcityname", header: "Boarding City" },
        // { field: "travelRemarks", header: "Travel Remarks" },
        // { field: "designation", header: "Designation" },
        { field: "inFlightBookingStatus", header: "In Flight Booking Status" },
        { field: "outFlightBookingStatus", header: "Out Flight Booking Status" },
        { field: "inBatchBooking", header: "In Batch Booking" },
        { field: "outBatchBooking", header: "Out Batch Booking" },
    ];

    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    // case "City":
                    //     if (lazyState.current.filterCity != null) {
                    //         lazyState.current.filterCity = null;
                    //         isFiltered = true;
                    //         return setFilterCity(null);
                    //     }
                    //     break;
                    // case "Zone":
                    //     if (lazyState.current.filterZone != null) {
                    //         lazyState.current.filterZone = null;
                    //         isFiltered = true;
                    //         return setFilterZone(null);
                    //     }
                    //     break;
                    case "Designation":
                        if (lazyState.current.filterDesignation != null) {
                            lazyState.current.filterDesignation = null;
                            isFiltered = true;
                            return lazyState.current.filterDesignation;
                        }
                        break;
                    // case "Boarding City":
                    // if(lazyState.current.filterBoardingCity != null){
                    //     lazyState.current.filterBoardingCity = null;
                    //     isFiltered = true;
                    //     return setFilterBoardingCity(null);
                    // }
                    // break;
                    // case "Trico Confirmed":
                    //     if(lazyState.current.filterTricoConfirmed != null){
                    //         lazyState.current.filterTricoConfirmed = null;
                    //         isFiltered = true;
                    //         return lazyState.current.filterTricoConfirmed;
                    //     }
                    //     break;
                    case "Out Flight Booking Status":
                        if (lazyState.current.filterOutFlightBookingStatus != null) {
                            lazyState.current.filterOutFlightBookingStatus = null;
                            isFiltered = true;
                            return lazyState.current.filterOutFlightBookingStatus;
                        }
                        break;
                    case "In Flight Booking Status":
                        if (lazyState.current.filterInFlightBookingStatus != null) {
                            lazyState.current.filterInFlightBookingStatus = null;
                            isFiltered = true;
                            return lazyState.current.filterInFlightBookingStatus;
                        }
                        break;
                    case "Travel Remarks":
                        if (lazyState.current.filterTravelRemarks != null) {
                            lazyState.current.filterTravelRemarks = null;
                            isFiltered = true;
                            return setFilterTravelRemarks(null);
                        }
                        break;
                    case "In Batch Booking":
                        if (lazyState.current.filterInBatchBooking != null) {
                            lazyState.current.filterInBatchBooking = null;
                            isFiltered = true;
                            return setFilterInBatchBooking(null);
                        }
                        break;
                    case "Out Batch Booking":
                        if (lazyState.current.filterOutBatchBooking != null) {
                            lazyState.current.filterOutBatchBooking = null;
                            isFiltered = true;
                            return setFilterOutBatchBooking(null);
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            // case "City":
            //     return CityFilterTemplate;
            // case "Zone":
            //     return ZoneFilterTemplate;
            // case "Trico Confirmed":
            //     return TricoConfirmedFilterTemplate;
            // case "Travel Remarks":
            //     return TravelRemarksFilterTemplate;
            case "Designation":
                return DesignationFilterTemplate;
            case "Out Flight Booking Status":
                return OutFlightBookingFilterTemplate;
            case "In Flight Booking Status":
                return InFlightBookingFilterTemplate;
            case "In Batch Booking":
                return InBatchBookingFilterTemplate;
            case "Out Batch Booking":
                return OutBatchBookingFilterTemplate;
        }
    };

    const sortColumn = {
        // "City": 'CityId',
        // "Zone": 'Zone',
        // "Trico Confirmed": 'TricoConfirmed',
        // "Travel Remarks": 'TravelRemarks',
        "Boarding City": "Boardingcity",
        "In Flight Booking Status": "InFlightBookingStatus",
        "Out Flight Booking Status": "OutFlightBookingStatus",
        "In Batch Booking": "InBatchBooking",
        "Out Batch Booking": "OutBatchBooking",
        Designation: "Designation",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: "15%", minWidth: "16rem" }} style={{ overflowWrap: "anywhere" }} sortField={sortColumn[col.header]} filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });

    //For Formating DateTime
    const DepartOnFormate = (data) => {
        if (data.departureDateTime == null) {
            return null;
        } else {
            const datetime = moment(data.departureDateTime).format("DD/MM/YYYY HH:mm");
            return datetime;
        }
    };

    const ArriveAtFormate = (data) => {
        if (data.arrivalDateTime == null) {
            return null;
        } else {
            const datetime = moment(data.arrivalDateTime).format("DD/MM/YYYY HH:mm");
            return datetime;
        }
    };

    const ViewAttendeeIcon = (rowData) => {
        if (window.location.href.includes("arrivalsummary")) {
            return (
                <div className="actions">
                    <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "ArrivalSummary" } })}>
                        {" "}
                        <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="actions">
                    <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "DepartureSummary" } })}>
                        {" "}
                        <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                    </Link>
                </div>
            );
        }
    };

    //#endregion

    return (
        <AccessControl allowedPermissions={["list:participant"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid  fa-plane fa-rotate-110 mr-2"></i>
                                        {travelSummary}
                                    </span>
                                </h5>
                                {/* <h5><span><i class = {travelSummary == "Departure Summary" ? "fa fa-solid  fa-plane fa-rotate-110 mr-2" : "fa fa-solid  fa-plane fa-rotate-180 mr-2 " }></i>{travelSummary}</span></h5> */}
                                <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                    dataKey="id"
                                    className="datatable-responsive"
                                    responsiveLayout="scroll"
                                    filterDisplay={"row"}
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column body={ViewAttendees} frozen sortField="Firstname" field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                    <Column field="flightNo" sortField="FlightNo" header="Flight No" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={FlightNoFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column field="returnFlightNo" sortField="ReturnFlightNo" header="Return Flight No" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={ReturnFlightNoFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>

                                    <Column field="mobileNo" sortField="MobileNo" header="Mobile No." sortable headerStyle={{ width: "20%", minWidth: "20rem" }} style={{ overflowWrap: "anywhere" }}></Column>

                                    <Column field="departFrom" sortField="DepartFrom" header="Depart From" sortable headerStyle={{ width: "20%", minWidth: "20rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={DepartFromFilterTemplate}></Column>
                                    <Column field="arriveAt" sortField="ArriveAt" header="Arrive At" sortable headerStyle={{ width: "20%", minWidth: "20rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={ArriveAtFilterTemplate}></Column>
                                    <Column body={DepartOnFormate} dateFormat="dd/mm/yyyy" sortField="DepartureDateTime" header="Depart On" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column body={ArriveAtFormate} dateFormat="dd/mm/yyyy" sortField="ArrivalDateTime" header="Arrive At" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }}></Column>
                                    {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate}></Column> */}
                                    {/* <Column field="designation" sortField="Designation" header="Designation" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={DesignationFilterTemplate}></Column> */}
                                    {/* <Column field="company" sortField="company" header="Company" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} ></Column>
                                        <Column body={ButtonMailto} sortField="Email" header="Email" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                        <Column body={ButtonPhoneto} sortField="MobileNo" header="Mobile No." sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column> */}
                                    {/* {boardingCity ? <Column field="boardingcityname" sortField="Boardingcity" header="Boarding City" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate} ></Column> : null} */}
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};
export default ArrivalSummary;
