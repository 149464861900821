import React, { useState, useEffect,useRef } from "react";
import axios from 'axios';
import '../../assets/layout/styling.css';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Toast } from 'primereact/toast';

const Doctorview = () => {

    let emptyparticipant = {
        id: '',
        honorfic: '',
        titleName: '',
        firstname: '',
        lastname: '',
        participanttype: '',
        designation: '',
        speciality: '',
        qualification: '',
        mbbsregistrationnumber: '',
        dmregistrationnumber: '',
        mobileno: '',
        email: '',
        phone: '',
        gender: '',
        countryid: '',
        countryname: '',
        stateid: '',
        statename: '',
        cityid: '',
        cityname: '',
        boardingcity: '',
        boardingcityname: '',
        zone: '',
        hospitalname: '',
        address: '',
        clinicphone: '',
        hotel: '',
        registrationstatus: '',
        travelmode_inbound: '',
        travelmode_outbound: '',
        needaccomodation: '',
        needaccomodationcode: null,
        participantremarks: '',
        isboarding: true,
        fullname:''
    };

    // Hooks
    const [doctordetails, setdoctordetails] = useState([]);
    const [participantdata, setparticipantdata] = useState(emptyparticipant);
    const [ticketData, setTicketData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const Email = localStorage.getItem('Email');
    var token = localStorage.getItem('access_token');
    const toast = useRef(null);

    // On Screen Load
    useEffect(() => {
        getparticipantbyid();
    }, []);

    // Get By Id
    const getparticipantbyid = () => {
        setIsLoading(true);
        axios.get('/api/Participant/GetParticipantByEmail/' + Email)
            .then((Response) => {
                setdoctordetails(Response.data);
                let _participant = { ...Response.data };
                _participant['id'] = (Response.data.id) || '';
                _participant['honorfic'] = (Response.data.honorfic) || '';
                _participant['firstname'] = (Response.data.firstname) || '';
                _participant['lastname'] = (Response.data.lastname) || '';
                _participant['titleName'] = (Response.data.titleName) || '';
                _participant['fullname'] = (Response.data.fullName) || '';
                _participant['participanttype'] = (Response.data.participantType) || '';
                _participant['designation'] = (Response.data.designation) || '';
                _participant['speciality '] = (Response.data.speciality);
                _participant['qualification'] = (Response.data.qualification) || '';
                _participant['mbbsregistrationnumber'] = (Response.data.mbbsRegistrationNumber) || '';
                _participant['dmregistrationnumber'] = (Response.data.dmRegistrationNumber) || '';
                _participant['mobileno'] = (Response.data.mobileNo) || '';
                _participant['email'] = (Response.data.email) || '';
                _participant['phone'] = (Response.data.phone) || '';
                _participant['gender'] = (Response.data.gender) || '';
                _participant['countryid'] = (Response.data.countryid) || '';
                _participant['stateid'] = (Response.data.stateid) || '';
                _participant['cityid'] = (Response.data.cityid) || '';
                _participant['boardingcity'] = (Response.data.boardingcityid) || '';
                _participant['zone'] = (Response.data.zone) || '';
                _participant['hospitalname'] = (Response.data.hospitalName) || '';
                _participant['address'] = (Response.data.address) || '';
                _participant['clinicphone'] = (Response.data.clinicphone) || '';
                _participant['travelmode_inbound'] = (Response.data.travelmode_Inbound) || '';
                _participant['travelmode_outbound'] = (Response.data.travelmode_Outbound) || '';
                _participant['needaccomodation'] = (Response.data.accomodation);
                _participant['needaccomodationcode'] = (Response.data.needAccomodation);
                _participant['participantremarks'] = (Response.data.participantRemarks) || '';
                _participant['registrationstatus'] = (Response.data.registrationStatus) || '';
                _participant['hotel'] = (Response.data.hotel) || '';
                setparticipantdata(_participant);
                GetTicketDetails(Response.data.id);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    }

    //Ticket details
    const GetTicketDetails = (id) => {
        axios.post('/api/Participant/GetTicketNameByParticipantId?id=' + id)
            .then(Response => {
                setTicketData(Response.data)
            })
    }

    const DownloadFile = (ticketpath) => {
        setIsLoading(true);
        const headers = {
            "Accept-Type": "application/octet-stream",
            "Authorization": "Bearer " + token
        }
        axios.get('/api/Participant/files?filename=' + ticketpath, {
            headers: headers, credentials: "include", responseType: 'blob'
        }).then(
            Response => {
                setIsLoading(false);
                var data = new Blob([Response.data], { type: "application/pdf" });
                const a = document.createElement('a');
                var csvURL = window.URL.createObjectURL(data);
                a.href = csvURL;
                a.download = ticketpath.split('/').pop();
                a.click();
                window.URL.revokeObjectURL(csvURL);
            }
        ).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    }

    return (
        <div>
            <Toast ref={toast} />
            {isLoading ? <LoadingSpinner /> :
                (
                    <>
                        <div className="card" >

                            <h3 style={{ backgroundColor: "#2a6697", padding: "8px", marginRight: "5px", color: 'white' }}>Personal Details</h3>
                            <table >
                                <tbody>
                                    <tr>
                                        <td className="doctorviewtable">Name For Certificate / Ticket</td>
                                        <td className="doctorviewtable">{participantdata.fullname}</td>
                                        <td className="doctorviewtable">Name</td>
                                        <td className="doctorviewtable">{participantdata.fullname}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Designation</td>
                                        <td className="doctorviewtable">{participantdata.designation}</td>
                                        <td className="doctorviewtable">Participant Type</td>
                                        <td className="doctorviewtable">{participantdata.participanttype}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Mobile No</td>
                                        <td className="doctorviewtable">{participantdata.mobileno}</td>
                                        <td className="doctorviewtable">Speciality</td>
                                        <td className="doctorviewtable">{participantdata.speciality}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Qualification</td>
                                        <td className="doctorviewtable">{participantdata.qualification}</td>
                                        <td className="doctorviewtable">Phone</td>
                                        <td className="doctorviewtable">{participantdata.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Email Id</td>
                                        <td className="doctorviewtable">{participantdata.email}</td>
                                        <td className="doctorviewtable">Country</td>
                                        <td className="doctorviewtable">{participantdata.countryname}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Gender</td>
                                        <td className="doctorviewtable">{participantdata.gender}</td>
                                        <td className="doctorviewtable">City</td>
                                        <td className="doctorviewtable">{participantdata.cityname}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">State</td>
                                        <td className="doctorviewtable">{participantdata.statename}</td>
                                        <td className="doctorviewtable">Zone</td>
                                        <td className="doctorviewtable">{participantdata.zone}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">BoardingCity</td>
                                        <td className="doctorviewtable">{participantdata.boardingcityname}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 style={{ backgroundColor: "#2a6697", padding: "8px", marginRight: "5px", color: 'white' }}>Clinic Details</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="doctorviewtable">Hospital / Clinic Name </td>
                                        <td className="doctorviewtable">{participantdata.hospitalname}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Address</td>
                                        <td className="doctorviewtable">{participantdata.address}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">Clinic Phone </td>
                                        <td className="doctorviewtable">{participantdata.clinicphone}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 className={participantdata.needaccomodation == "No" ? "hidden" : ""} style={{ backgroundColor: "#2a6697", padding: "8px", marginRight: "5px", color: 'white' }}>Accomodation Details</h3>
                            <table className={participantdata.needaccomodation == "No" ? "hidden" : ""}>
                                <tbody>
                                    <tr>
                                        <td className="doctorviewtable">Need Accommodation</td>
                                        <td className="doctorviewtable">{participantdata.needaccomodation}</td>
                                        <td className="doctorviewtable">Hotel Name</td>
                                        <td className="doctorviewtable">{participantdata.hotel}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 style={{ backgroundColor: "#2a6697", padding: "8px", color: 'white' }}>Air Ticket Booking Details</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="doctorviewtable">Travel Mode (Inbound) </td>
                                        <td className="doctorviewtable">{participantdata.travelmode_inbound}</td>
                                        <td className="doctorviewtable">Travel Mode (Outbound)</td>
                                        <td className="doctorviewtable">{participantdata.travelmode_outbound}</td>
                                    </tr>
                                    <tr>
                                        <td className="doctorviewtable">In Flight Booking Status </td>
                                        <td className="doctorviewtable">Approved </td>
                                        <td className="doctorviewtable">Out Flight Booking Status</td>
                                        <td className="doctorviewtable">Approved </td>
                                    </tr>
                                    {ticketData.length > 0 ?
                                        <tr>
                                            <td className="doctorviewtable">Download Tickets : </td>
                                            {ticketData.map((item) => {
                                                return (
                                                    <div className="flex align-items-center">
                                                        <label className="ml-2 mt-2">{item.title}</label>
                                                        <i className="fa fa-download ml-2 mt-2" style={{ cursor: 'pointer' }} onClick={(e) => DownloadFile(item.ticketPath)}></i>
                                                    </div>
                                                )
                                            })}
                                        </tr> : <></>}
                                </tbody>
                            </table>
                        </div>
                    </>
                )
            }
        </div >)
}
export default Doctorview;