import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import * as Designations from "../../enums/Designations";
import * as Travelmodes from "../../enums/Travelmodes";
import * as RegistrationStatus from "../../enums/RegistrationStatus";
import { Button } from "primereact/button";
import exportData from "../../services/exportToExcelService";
import { Toolbar } from "primereact/toolbar";
import * as Genders from "../../enums/Genders";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { ToggleButton } from "primereact/togglebutton";
import QRCode from "qrcode.react";
import * as Zones from "../../enums/Zones";
import * as FlightBookingStatus from "../../enums/BookingStatus";
import * as GlobalEnum from "../../enums/GlobalEnum";
import * as Occupancys from '../../enums/Occupancy';

const ViewAllAttendees = (props) => {
    let emptyParticipantQRCode = [
        {
            id: "",
            ImageUrl: "",
            ImageName: "",
            MobileNo: "",
            UserName: "",
            Url: "",
            Registeredby: "",
            companyContactPerson: "",
        },
    ];

    let participantQRCode = {
        qrId: "",
        url: "",
    };

    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [id, setid] = useState(new Array());
    const [Userid, setUserid] = useState(new Array());
    const [MobileNo, setMobileNo] = useState(new Array());
    const [userObj, setuserObj] = useState(new Array());
    const [fullName, setfullName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hospitalNamefilter, sethospitalNamefilter] = useState(null);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [selecteddata, setselecteddata] = useState(null);
    
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [boardingCityList, setBoardingCityList] = useState([]);
    const [inBatchList, setInBatchList] = useState([]);
    const [outBatchList, setOutBatchList] = useState([]);
    const [hotelList, setHotelList] = useState([]);

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null,
        ParticipantNameFilter: null,
        EmailFilter: null,
        MobileNoFilter: null,
        CityFilter: null,
        StateFilter: null,
        CountryFilter: null,
        BoardingCityFilter: null,
        HospitalNameFilter: null,
        Designationfilter: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        Genderfilter: null,
        registrationStatus: null,
        registeredby: null,
        registeredbynumber: null,
        serialnumber: null,
        zonefilter: null,
        inbatchfilter: null,
        outbatchfilter: null,
        inflightstatusfilter: null,
        outflightstatusfilter: null,

        InFlightNo1filter: null,
        InAirlineName1filter: null,
        InDepartFrom1filter: null,
        InArriveAt1filter: null,

        InFlightNo2filter: null,
        InAirlineName2filter: null,
        InDepartFrom2filter: null,
        InArriveAt2filter: null,

        InFlightNo3filter: null,
        InAirlineName3filter: null,
        InDepartFrom3filter: null,
        InArriveAt3filter: null,

        InFlightNo4filter: null,
        InAirlineName4filter: null,
        InDepartFrom4filter: null,
        InArriveAt4filter: null,

        OutFlightNo1filter: null,
        OutAirlineName1filter: null,
        OutDepartFrom1filter: null,
        OutArriveAt1filter: null,

        OutFlightNo2filter: null,
        OutAirlineName2filter: null,
        OutDepartFrom2filter: null,
        OutArriveAt2filter: null,

        OutFlightNo3filter: null,
        OutAirlineName3filter: null,
        OutDepartFrom3filter: null,
        OutArriveAt3filter: null,

        OutFlightNo4filter: null,
        OutAirlineName4filter: null,
        OutDepartFrom4filter: null,
        OutArriveAt4filter: null,

        TricoConfirmedfilter: null,
        TicketBookedfilter: null,
        Presentfilter: null,
        DayTwoPresentfilter: null,
        IsArrivedfilter: null,
        IsCheckedInfilter: null,
        NeedAccomodationfilter: null,
        HotelFilter: null,
        occupancy: null
    }); // For Sorting And Pagination Purpose

    const WhatsAppMessageStatus = useRef({
        Doctor: null,
        FieldPerson: null,
        Admin: null,
    });
    const [RegisteredName, setRegisteredName] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const [RegisteredByNumber, setRegisteredByNumber] = useState(null);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [NameFrozen, setNameFrozen] = useState(false);
    const [ActionDisable, setActionDisable] = useState(true);
    const [QrCodes, setQRCodes] = useState(new Array());
    const [UrlData, setUrlData] = useState(null);
    const [WhatsAppUrlData, setWhatsAppUrlData] = useState(null);
    const [SeemamamNo, setSeemamamNo] = useState(null);
    const [ApiHeaders, setApiHeaders] = useState(null);
    const [WhatsappStatus, setWhatsAppStatus] = useState(null);
    const [User, setUser] = useState([]);

    const [InFlightNo1filter, setInFlightNo1filter] = useState(null);
    const [InAirlineName1filter, setInAirlineName1filter] = useState(null);
    const [InDepartFrom1filter, setInDepartFrom1filter] = useState(null);
    const [InArriveAt1filter, setInArriveAt1filter] = useState(null);

    const [InFlightNo2filter, setInFlightNo2filter] = useState(null);
    const [InAirlineName2filter, setInAirlineName2filter] = useState(null);
    const [InDepartFrom2filter, setInDepartFrom2filter] = useState(null);
    const [InArriveAt2filter, setInArriveAt2filter] = useState(null);

    const [InFlightNo3filter, setInFlightNo3filter] = useState(null);
    const [InAirlineName3filter, setInAirlineName3filter] = useState(null);
    const [InDepartFrom3filter, setInDepartFrom3filter] = useState(null);
    const [InArriveAt3filter, setInArriveAt3filter] = useState(null);

    const [InFlightNo4filter, setInFlightNo4filter] = useState(null);
    const [InAirlineName4filter, setInAirlineName4filter] = useState(null);
    const [InDepartFrom4filter, setInDepartFrom4filter] = useState(null);
    const [InArriveAt4filter, setInArriveAt4filter] = useState(null);

    const [OutFlightNo1filter, setOutFlightNo1filter] = useState(null);
    const [OutAirlineName1filter, setOutAirlineName1filter] = useState(null);
    const [OutDepartFrom1filter, setOutDepartFrom1filter] = useState(null);
    const [OutArriveAt1filter, setOutArriveAt1filter] = useState(null);

    const [OutFlightNo2filter, setOutFlightNo2filter] = useState(null);
    const [OutAirlineName2filter, setOutAirlineName2filter] = useState(null);
    const [OutDepartFrom2filter, setOutDepartFrom2filter] = useState(null);
    const [OutArriveAt2filter, setOutArriveAt2filter] = useState(null);

    const [OutFlightNo3filter, setOutFlightNo3filter] = useState(null);
    const [OutAirlineName3filter, setOutAirlineName3filter] = useState(null);
    const [OutDepartFrom3filter, setOutDepartFrom3filter] = useState(null);
    const [OutArriveAt3filter, setOutArriveAt3filter] = useState(null);

    const [OutFlightNo4filter, setOutFlightNo4filter] = useState(null);
    const [OutAirlineName4filter, setOutAirlineName4filter] = useState(null);
    const [OutDepartFrom4filter, setOutDepartFrom4filter] = useState(null);
    const [OutArriveAt4filter, setOutArriveAt4filter] = useState(null);

    const [TricoConfirmfilter, setTricoConfirmfilter] = useState(null);
    const [HotelFilter, setHotelFilter] = useState(null);

    let qrData = emptyParticipantQRCode;

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const CompanyId = localStorage.getItem("CompanyId");
    const UserID = localStorage.getItem("UserID");
    const UserName = localStorage.getItem("Username");
    var token = localStorage.getItem("access_token");
    var usrDetails = userDetails();

    const screenHeight = window.innerHeight;
    const scrollHeight = screenHeight;

    // On Screen Load
    useEffect(() => {
        if (props?.location.state?.detail == "AddAttendee") {
            toast.current.show({ severity: "success", summary: "Successful", detail: "New Attendee Added", life: 3000 });
        }
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
        getData();
        getFilterListsData();
    }, []);

    // Get All Participants
    const getparticipantlist = (isExportExcel = false, event) => {
        //Designation
        var listDesignationFilterString = null;
        if (lazyState.current.Designationfilter != null && lazyState.current.Designationfilter != undefined && lazyState.current.Designationfilter != '') {
            if (lazyState.current.Designationfilter.length > 0) {
                if (lazyState.current.Designationfilter.length > 1) {
                    listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name).join(',');
                } else {
                    listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name)[0];
                }
            }
        }

        //Zone
        var listZoneFilterString = null;
        if (lazyState.current.zonefilter != null && lazyState.current.zonefilter != undefined && lazyState.current.zonefilter != '') {
            if (lazyState.current.zonefilter.length > 0) {
                if (lazyState.current.zonefilter.length > 1) {
                    listZoneFilterString = lazyState.current.zonefilter.map(item => item.name).join(',');
                } else {
                    listZoneFilterString = lazyState.current.zonefilter.map(item => item.name)[0];
                }
            }
        }

        //Country
        var listCountryFilterString = null;
        if (lazyState.current.CountryFilter != null && lazyState.current.CountryFilter != undefined && lazyState.current.CountryFilter != '') {
            if (lazyState.current.CountryFilter.length > 0) {
                if (lazyState.current.CountryFilter.length > 1) {
                    listCountryFilterString = lazyState.current.CountryFilter.map(item => item.name).join(',');
                } else {
                    listCountryFilterString = lazyState.current.CountryFilter.map(item => item.name)[0];
                }
            }
        }

        //State
        var listStateFilterString = null;
        if (lazyState.current.StateFilter != null && lazyState.current.StateFilter != undefined && lazyState.current.StateFilter != '') {
            if (lazyState.current.StateFilter.length > 0) {
                if (lazyState.current.StateFilter.length > 1) {
                    listStateFilterString = lazyState.current.StateFilter.map(item => item.name).join(',');
                } else {
                    listStateFilterString = lazyState.current.StateFilter.map(item => item.name)[0];
                }
            }
        }

        //City
        var listCityFilterString = null;
        if (lazyState.current.CityFilter != null && lazyState.current.CityFilter != undefined && lazyState.current.CityFilter != '') {
            if (lazyState.current.CityFilter.length > 0) {
                if (lazyState.current.CityFilter.length > 1) {
                    listCityFilterString = lazyState.current.CityFilter.map(item => item.name).join(',');
                } else {
                    listCityFilterString = lazyState.current.CityFilter.map(item => item.name)[0];
                }
            }
        }

        //Boarding City
        var listBoardingCityFilterString = null;
        if (lazyState.current.BoardingCityFilter != null && lazyState.current.BoardingCityFilter != undefined && lazyState.current.BoardingCityFilter != '') {
            if (lazyState.current.BoardingCityFilter.length > 0) {
                if (lazyState.current.BoardingCityFilter.length > 1) {
                    listBoardingCityFilterString = lazyState.current.BoardingCityFilter.map(item => item.name).join(',');
                } else {
                    listBoardingCityFilterString = lazyState.current.BoardingCityFilter.map(item => item.name)[0];
                }
            }
        }

        //In Booking Batch
        var listInBatchFilterString = null;
        if (lazyState.current.inbatchfilter != null && lazyState.current.inbatchfilter != undefined && lazyState.current.inbatchfilter != '') {
            if (lazyState.current.inbatchfilter.length > 0) {
                if (lazyState.current.inbatchfilter.length > 1) {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name).join(',');
                } else {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Out Booking Batch
        var listOutBatchFilterString = null;
        if (lazyState.current.outbatchfilter != null && lazyState.current.outbatchfilter != undefined && lazyState.current.outbatchfilter != '') {
            if (lazyState.current.outbatchfilter.length > 0) {
                if (lazyState.current.outbatchfilter.length > 1) {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name).join(',');
                } else {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Hotel
        var listHotelFilterString = null;
        if (lazyState.current.HotelFilter != null && lazyState.current.HotelFilter != undefined && lazyState.current.HotelFilter != '') {
            if (lazyState.current.HotelFilter.length > 0) {
                if (lazyState.current.HotelFilter.length > 1) {
                    listHotelFilterString = lazyState.current.HotelFilter.map(item => item.name).join(',');
                } else {
                    listHotelFilterString = lazyState.current.HotelFilter.map(item => item.name)[0];
                }
            }
        }

        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetTicketPath", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "",
                registrationStatus: lazyState.current.registrationStatus ? lazyState.current.registrationStatus.name.trim() : null,
                registrationStatusSearchOprator: lazyState.current.registrationStatus == null ? "NotEquals" : "",
                RegistrationStatusonlynewapproved: "true",
                CompanyFilter: lazyState.current.CompanyFilter ? lazyState.current.CompanyFilter.trim() : null,
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                Registeredby: usrDetails.role === "SUBMR" ? UserID : null,
                FullName: lazyState.current.ParticipantNameFilter ? lazyState.current.ParticipantNameFilter.trim() : null,
                Email: lazyState.current.EmailFilter ? lazyState.current.EmailFilter.trim() : null,
                MobileNo: lazyState.current.MobileNoFilter ? lazyState.current.MobileNoFilter.trim() : null,
                Designation: listDesignationFilterString ? listDesignationFilterString : null,
                Gender: lazyState.current.Genderfilter ? lazyState.current.Genderfilter.name.trim() : null,
                ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
                FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
                City: listCityFilterString ? listCityFilterString : null,
                State: listStateFilterString ? listStateFilterString : null,
                Country: listCountryFilterString ? listCountryFilterString : null,
                BoardingCity: listBoardingCityFilterString ? listBoardingCityFilterString : null,
                HospitalName: lazyState.current.HospitalNameFilter ? lazyState.current.HospitalNameFilter.trim() : null,
                RegisteredByPhone: lazyState.current.registeredbynumber ? lazyState.current.registeredbynumber.trim() : null,
                UserRegisteredBy: lazyState.current.registeredby ? lazyState.current.registeredby.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
                Zone: listZoneFilterString ? listZoneFilterString : null,
                InBatchBooking: listInBatchFilterString ? listInBatchFilterString : null,
                OutBatchBooking: listOutBatchFilterString ? listOutBatchFilterString : null,
                TicketInFlightBookingStatus: lazyState.current.inflightstatusfilter ? lazyState.current.inflightstatusfilter.code.trim() : null,
                TicketOutFlightBookingStatus: lazyState.current.outflightstatusfilter ? lazyState.current.outflightstatusfilter.code.trim() : null,
                InFlightNo1: lazyState.current.InFlightNo1filter ? lazyState.current.InFlightNo1filter.trim() : null,
                InAirlineName1: lazyState.current.InAirlineName1filter ? lazyState.current.InAirlineName1filter.trim() : null,
                InDepartFrom1: lazyState.current.InDepartFrom1filter ? lazyState.current.InDepartFrom1filter.trim() : null,
                InArriveAt1: lazyState.current.InArriveAt1filter ? lazyState.current.InArriveAt1filter.trim() : null,
                InFlightNo2: lazyState.current.InFlightNo2filter ? lazyState.current.InFlightNo2filter.trim() : null,
                InAirlineName2: lazyState.current.InAirlineName2filter ? lazyState.current.InAirlineName2filter.trim() : null,
                InDepartFrom2: lazyState.current.InDepartFrom2filter ? lazyState.current.InDepartFrom2filter.trim() : null,
                InArriveAt2: lazyState.current.InArriveAt2filter ? lazyState.current.InArriveAt2filter.trim() : null,
                InFlightNo3: lazyState.current.InFlightNo3filter ? lazyState.current.InFlightNo3filter.trim() : null,
                InAirlineName3: lazyState.current.InAirlineName3filter ? lazyState.current.InAirlineName3filter.trim() : null,
                InDepartFrom3: lazyState.current.InDepartFrom3filter ? lazyState.current.InDepartFrom3filter.trim() : null,
                InArriveAt3: lazyState.current.InArriveAt3filter ? lazyState.current.InArriveAt3filter.trim() : null,
                InFlightNo4: lazyState.current.InFlightNo4filter ? lazyState.current.InFlightNo4filter.trim() : null,
                InAirlineName4: lazyState.current.InAirlineName4filter ? lazyState.current.InAirlineName4filter.trim() : null,
                InDepartFrom4: lazyState.current.InDepartFrom4filter ? lazyState.current.InDepartFrom4filter.trim() : null,
                InArriveAt4: lazyState.current.InArriveAt4filter ? lazyState.current.InArriveAt4filter.trim() : null,

                OutFlightNo1: lazyState.current.OutFlightNo1filter ? lazyState.current.OutFlightNo1filter.trim() : null,
                OutAirlineName1: lazyState.current.OutAirlineName1filter ? lazyState.current.OutAirlineName1filter.trim() : null,
                OutDepartFrom1: lazyState.current.OutDepartFrom1filter ? lazyState.current.OutDepartFrom1filter.trim() : null,
                OutArriveAt1: lazyState.current.OutArriveAt1filter ? lazyState.current.OutArriveAt1filter.trim() : null,
                OutFlightNo2: lazyState.current.OutFlightNo2filter ? lazyState.current.OutFlightNo2filter.trim() : null,
                OutAirlineName2: lazyState.current.OutAirlineName2filter ? lazyState.current.OutAirlineName2filter.trim() : null,
                OutDepartFrom2: lazyState.current.OutDepartFrom2filter ? lazyState.current.OutDepartFrom2filter.trim() : null,
                OutArriveAt2: lazyState.current.OutArriveAt2filter ? lazyState.current.OutArriveAt2filter.trim() : null,
                OutFlightNo3: lazyState.current.OutFlightNo3filter ? lazyState.current.OutFlightNo3filter.trim() : null,
                OutAirlineName3: lazyState.current.OutAirlineName3filter ? lazyState.current.OutAirlineName3filter.trim() : null,
                OutDepartFrom3: lazyState.current.OutDepartFrom3filter ? lazyState.current.OutDepartFrom3filter.trim() : null,
                OutArriveAt3: lazyState.current.OutArriveAt3filter ? lazyState.current.OutArriveAt3filter.trim() : null,
                OutFlightNo4: lazyState.current.OutFlightNo4filter ? lazyState.current.OutFlightNo4filter.trim() : null,
                OutAirlineName4: lazyState.current.OutAirlineName4filter ? lazyState.current.OutAirlineName4filter.trim() : null,
                OutDepartFrom4: lazyState.current.OutDepartFrom4filter ? lazyState.current.OutDepartFrom4filter.trim() : null,
                OutArriveAt4: lazyState.current.OutArriveAt4filter ? lazyState.current.OutArriveAt4filter.trim() : null,

                TricoConfirmed: lazyState.current.TricoConfirmedfilter ? lazyState.current.TricoConfirmedfilter.trim() : null,
                Present: lazyState.current.Presentfilter ? lazyState.current.Presentfilter.code.trim() : null,
                DayTwoPresent: lazyState.current.DayTwoPresentfilter ? lazyState.current.DayTwoPresentfilter.code.trim() : null,
                TicketBooked: lazyState.current.TicketBookedfilter ? lazyState.current.TicketBookedfilter.code.trim() : null,
                IsArrived: lazyState.current.IsArrivedfilter ? lazyState.current.IsArrivedfilter.code.trim() : null,
                IsCheckedIn: lazyState.current.IsCheckedInfilter ? lazyState.current.IsCheckedInfilter.code.trim() : null,

                NeedAccomodation: lazyState.current.NeedAccomodationfilter ? lazyState.current.NeedAccomodationfilter.code.trim() : null,
                HotelFilter: listHotelFilterString ? listHotelFilterString : null,
                OccupancyFilter: lazyState.current.occupancy ? lazyState.current.occupancy.code : null,
                ParticipantHotel: true,
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Hospital: dataObj.hospitalName,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        MobileNo: dataObj.mobileNo,
                        Registrationstatus: dataObj.registrationStatus,
                        Gender: dataObj.gender,
                        Designation: dataObj.designation,
                        Zone: dataObj.zone,
                        Country: dataObj.countryname,
                        State: dataObj.statename,
                        City: dataObj.cityname,
                        BoardingCity: dataObj.boardingcityname,
                        TravelInBound: dataObj.travelmode_Inbound,
                        TravelOutBound: dataObj.travelmode_Outbound,
                        InBatch: dataObj.inBatchBooking,
                        InFlightBookingStatus: dataObj.inFlightBookingStatus,
                        INF1FlightNo: dataObj.inFlightNo1,
                        INF1Airline: dataObj.inAirlineName1,
                        INF1DepartFrom: dataObj.inDepartFrom1,
                        INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF1ArriveAt: dataObj.inArriveAt1,
                        INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        INF2FlightNo: dataObj.inFlightNo2,
                        INF2Airline: dataObj.inAirlineName2,
                        INF2DepartFrom: dataObj.inDepartFrom2,
                        INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF2ArriveAt: dataObj.inArriveAt2,
                        INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        INF3FlightNo: dataObj.inFlightNo3,
                        INF3Airline: dataObj.inAirlineName3,
                        INF3DepartFrom: dataObj.inDepartFrom3,
                        INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF3ArriveAt: dataObj.inArriveAt3,
                        INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        INF4FlightNo: dataObj.inFlightNo4,
                        INF4Airline: dataObj.inAirlineName4,
                        INF4DepartFrom: dataObj.inDepartFrom4,
                        INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        INF4ArriveAt: dataObj.inArriveAt4,
                        INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OutBatch: dataObj.outBatchBooking,
                        OutFlightBookingStatus: dataObj.outFlightBookingStatus,
                        OUTF1FlightNo: dataObj.outFlightNo1,
                        OUTF1Airline: dataObj.outAirlineName1,
                        OUTF1DepartFrom: dataObj.outDepartFrom1,
                        OUTF1DepartureDateTime: dataObj.outDepartureDateTime1 ? moment(dataObj.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF1ArriveAt: dataObj.outArriveAt1,
                        OUTF1ArrivalDateTime: dataObj.outArrivalDateTime1 ? moment(dataObj.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2FlightNo: dataObj.outFlightNo2,
                        OUTF2Airline: dataObj.outAirlineName2,
                        OUTF2DepartFrom: dataObj.outDepartFrom2,
                        OUTF2DepartureDateTime: dataObj.outDepartureDateTime2 ? moment(dataObj.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF2ArriveAt: dataObj.outArriveAt2,
                        OUTF2ArrivalDateTime: dataObj.outArrivalDateTime2 ? moment(dataObj.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3FlightNo: dataObj.outFlightNo3,
                        OUTF3Airline: dataObj.outAirlineName3,
                        OUTF3DepartFrom: dataObj.outDepartFrom3,
                        OUTF3DepartureDateTime: dataObj.outDepartureDateTime3 ? moment(dataObj.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF3ArriveAt: dataObj.outArriveAt3,
                        OUTF3ArrivalDateTime: dataObj.outArrivalDateTime3 ? moment(dataObj.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4FlightNo: dataObj.outFlightNo4,
                        OUTF4Airline: dataObj.outAirlineName4,
                        OUTF4DepartFrom: dataObj.outDepartFrom4,
                        OUTF4DepartureDateTime: dataObj.outDepartureDateTime4 ? moment(dataObj.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        OUTF4ArriveAt: dataObj.outArriveAt4,
                        OUTF4ArrivalDateTime: dataObj.outArrivalDateTime4 ? moment(dataObj.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : "",
                        ApproveToSendTravelAgent: dataObj.approveToSendTravelAgent,
                        ApproveToSendParticipant: dataObj.approveToSendParticipant,
                        NeedAccomodation: dataObj.accomodation,
                        Hotel: dataObj.hotelName,
                        Occupancy: dataObj.occupancy,
                        IsCheckedIn: dataObj.isCheckedInYesNo,
                        IsArrived: dataObj.isArrivedYesNo,
                        Present: dataObj.presentYesNo,
                        DayTwoPresent: dataObj.dayTwoPresentYesNo,
                        IsTicketBooked: dataObj.isTicketBooked,
                        TricoConfirmed: dataObj.tricoConfirmed,
                        RegisteredBy: dataObj.userregisteredby,
                        RegisteredByContact: dataObj.registeredbynumber,
                    };
                    tempData.push(excelcolumnData);
                }
                if (isExportExcel) {
                    exportData(tempData, "AllAttendees");
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });

                setCountryList(separatedLists.Country || []);
                setStateList(separatedLists.State || []);
                setCityList(separatedLists.City || []);
                setBoardingCityList(separatedLists.BCity || []);
                setInBatchList(separatedLists.InBatch || []);
                setOutBatchList(separatedLists.OutBatch || []);
                setHotelList(separatedLists.Hotel || []);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" value={fullName} placeholder="Enter First Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const RegisteredbyFilterTemplate = () => {
        return <InputText id="registeredby" value={RegisteredName} placeholder="Enter Registeredby" onChange={(e) => setRegisteredName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const RegisteredbyNumberFilterTemplate = () => {
        return <InputText id="registeredbynumber" value={RegisteredByNumber} placeholder="Registeredby Number" onChange={(e) => setRegisteredByNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospitalname" value={hospitalNamefilter} placeholder="Enter Hospital Name" onChange={(e) => sethospitalNamefilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={companyNameFilter} placeholder="Enter Company" onChange={(e) => setcompanyNameFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="Mobile" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.Designationfilter}
                options={Designations.Designation}
                itemTemplate={DesignationFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => DesignationFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const DesignationFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const DesignationFilterBodyTemplate = (rowData) => {
        const designation = rowData.designation;
        return (
            <div className="flex align-items-center gap-2">
                <span>{designation}</span>
            </div>
        );
    };

    const DesignationFilterClick = (e) => {
        lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { Designation: e.value });
        e.preventDefault();
    }

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Genderfilter}
                options={Genders.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Genderfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Gender: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const RegistrationStatusTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.registrationStatus}
                options={RegistrationStatus.RegistrationStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.registrationStatus = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { registrationStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Status"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Toeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Toeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { ToEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Fromeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Fromeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { FromEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ZoneFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.zonefilter}
                options={Zones.Zone}
                itemTemplate={ZoneFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => ZoneFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const ZoneFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const ZoneFilterBodyTemplate = (rowData) => {
        const zone = rowData.zone;
        return (
            <div className="flex align-items-center gap-2">
                <span>{zone}</span>
            </div>
        );
    };

    const ZoneFilterClick = (e) => {
        lazyState.current.zonefilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { Zone: e.value });
        e.preventDefault();
    }

    const CountryFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CountryFilter}
                options={countryList}
                itemTemplate={CountryFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CountryFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CountryFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CountryFilterBodyTemplate = (rowData) => {
        const country = rowData.countryname;
        return (
            <div className="flex align-items-center gap-2">
                <span>{country}</span>
            </div>
        );
    };

    const CountryFilterClick = (e) => {
        lazyState.current.CountryFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { Country: e.value });
        e.preventDefault();
    }

    const StateFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.StateFilter}
                options={stateList}
                itemTemplate={StateFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => StateFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const StateFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const StateFilterBodyTemplate = (rowData) => {
        const state = rowData.statename;
        return (
            <div className="flex align-items-center gap-2">
                <span>{state}</span>
            </div>
        );
    };

    const StateFilterClick = (e) => {
        lazyState.current.StateFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { State: e.value });
        e.preventDefault();
    }

    const CityFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CityFilter}
                options={cityList}
                itemTemplate={CityFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CityFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CityFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CityFilterBodyTemplate = (rowData) => {
        const city = rowData.cityname;
        return (
            <div className="flex align-items-center gap-2">
                <span>{city}</span>
            </div>
        );
    };

    const CityFilterClick = (e) => {
        lazyState.current.CityFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { City: e.value });
        e.preventDefault();
    }

    const BoardingCityFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.BoardingCityFilter}
                options={boardingCityList}
                itemTemplate={BoardingCityFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => BoardingCityFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const BoardingCityFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const BoardingCityFilterBodyTemplate = (rowData) => {
        const boardingcity = rowData.boardingcityname;
        return (
            <div className="flex align-items-center gap-2">
                <span>{boardingcity}</span>
            </div>
        );
    };

    const BoardingCityFilterClick = (e) => {
        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { City: e.value });
        e.preventDefault();
    }

    const InBatchFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.inbatchfilter}
                options={inBatchList}
                itemTemplate={InBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => InBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const InBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const InBatchFilterBodyTemplate = (rowData) => {
        const inBatch = rowData.inBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{inBatch}</span>
            </div>
        );
    };

    const InBatchFilterClick = (e) => {
        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { InBatch: e.value });
        e.preventDefault();
    }

    const OutBatchFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.outbatchfilter}
                options={outBatchList}
                itemTemplate={OutBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => OutBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const OutBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const OutBatchFilterBodyTemplate = (rowData) => {
        const inBatch = rowData.inBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{inBatch}</span>
            </div>
        );
    };

    const OutBatchFilterClick = (e) => {
        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { OutBatch: e.value });
        e.preventDefault();
    }

    const InFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.inflightstatusfilter}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.inflightstatusfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { InFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="InFlight Status"
                className="p-column-filter"
            />
        );
    };

    const OutFlightBookingFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.outflightstatusfilter}
                options={FlightBookingStatus.BookingStatus}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.outflightstatusfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { OutFlightBookingStatus: e.value });
                    e.preventDefault();
                }}
                placeholder="OutFlight Status"
                className="p-column-filter"
            />
        );
    };

    const InFlightNo1FilterTemplate = () => {
        return <InputText id="InFlightNo1" value={InFlightNo1filter} placeholder="Enter In Flight1" onChange={(e) => setInFlightNo1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName1FilterTemplate = () => {
        return <InputText id="InAirlineName1" value={InAirlineName1filter} placeholder="Enter In Airline1" onChange={(e) => setInAirlineName1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom1FilterTemplate = () => {
        return <InputText id="InDepartFrom1" value={InDepartFrom1filter} placeholder="Enter In Depart1" onChange={(e) => setInDepartFrom1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt1FilterTemplate = () => {
        return <InputText id="InArriveAt1" value={InArriveAt1filter} placeholder="Enter In Arrive1" onChange={(e) => setInArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo2FilterTemplate = () => {
        return <InputText id="InFlightNo2" value={InFlightNo2filter} placeholder="Enter In Flight2" onChange={(e) => setInFlightNo2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName2FilterTemplate = () => {
        return <InputText id="InAirlineName2" value={InAirlineName2filter} placeholder="Enter In Airline2" onChange={(e) => setInAirlineName2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom2FilterTemplate = () => {
        return <InputText id="InDepartFrom2" value={InDepartFrom2filter} placeholder="Enter In Depart2" onChange={(e) => setInDepartFrom2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt2FilterTemplate = () => {
        return <InputText id="InArriveAt2" value={InArriveAt2filter} placeholder="Enter In Arrive2" onChange={(e) => setInArriveAt2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo3FilterTemplate = () => {
        return <InputText id="InFlightNo3" value={InFlightNo3filter} placeholder="Enter In Flight3" onChange={(e) => setInFlightNo3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName3FilterTemplate = () => {
        return <InputText id="InAirlineName3" value={InAirlineName3filter} placeholder="Enter In Airline3" onChange={(e) => setInAirlineName3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom3FilterTemplate = () => {
        return <InputText id="InDepartFrom3" value={InDepartFrom3filter} placeholder="Enter In Depart3" onChange={(e) => setInDepartFrom3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt3FilterTemplate = () => {
        return <InputText id="InArriveAt3" value={InArriveAt3filter} placeholder="Enter In Arrive3" onChange={(e) => setInArriveAt3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InFlightNo4FilterTemplate = () => {
        return <InputText id="InFlightNo4" value={InFlightNo4filter} placeholder="Enter In Flight4" onChange={(e) => setInFlightNo4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InAirlineName4FilterTemplate = () => {
        return <InputText id="InAirlineName4" value={InAirlineName4filter} placeholder="Enter In Airline4" onChange={(e) => setInAirlineName4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InDepartFrom4FilterTemplate = () => {
        return <InputText id="InDepartFrom4" value={InDepartFrom4filter} placeholder="Enter In Depart4" onChange={(e) => setInDepartFrom4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InArriveAt4FilterTemplate = () => {
        return <InputText id="InArriveAt4" value={InArriveAt4filter} placeholder="Enter In Arrive4" onChange={(e) => setInArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo1FilterTemplate = () => {
        return <InputText id="OutFlightNo1" value={OutFlightNo1filter} placeholder="Enter Out Flight1" onChange={(e) => setOutFlightNo1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName1FilterTemplate = () => {
        return <InputText id="OutAirlineName1" value={OutAirlineName1filter} placeholder="Enter Out Airline1" onChange={(e) => setOutAirlineName1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom1FilterTemplate = () => {
        return <InputText id="OutDepartFrom1" value={OutDepartFrom1filter} placeholder="Enter Out Depart1" onChange={(e) => setOutDepartFrom1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt1FilterTemplate = () => {
        return <InputText id="OutArriveAt1" value={OutArriveAt1filter} placeholder="Enter Out Arrive1" onChange={(e) => setOutArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo2FilterTemplate = () => {
        return <InputText id="OutFlightNo2" value={OutFlightNo2filter} placeholder="Enter Out Flight2" onChange={(e) => setOutFlightNo2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName2FilterTemplate = () => {
        return <InputText id="OutAirlineName2" value={OutAirlineName2filter} placeholder="Enter Out Airline2" onChange={(e) => setOutAirlineName2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom2FilterTemplate = () => {
        return <InputText id="OutDepartFrom2" value={OutDepartFrom2filter} placeholder="Enter Out Depart2" onChange={(e) => setOutDepartFrom2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt2FilterTemplate = () => {
        return <InputText id="OutArriveAt2" value={OutArriveAt2filter} placeholder="Enter Out Arrive2" onChange={(e) => setOutArriveAt2filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo3FilterTemplate = () => {
        return <InputText id="OutFlightNo3" value={OutFlightNo3filter} placeholder="Enter Out Flight3" onChange={(e) => setOutFlightNo3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName3FilterTemplate = () => {
        return <InputText id="OutAirlineName3" value={OutAirlineName3filter} placeholder="Enter Out Airline3" onChange={(e) => setOutAirlineName3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom3FilterTemplate = () => {
        return <InputText id="OutDepartFrom3" value={OutDepartFrom3filter} placeholder="Enter Out Depart3" onChange={(e) => setOutDepartFrom3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt3FilterTemplate = () => {
        return <InputText id="OutArriveAt3" value={OutArriveAt3filter} placeholder="Enter Out Arrive3" onChange={(e) => setOutArriveAt3filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutFlightNo4FilterTemplate = () => {
        return <InputText id="OutFlightNo4" value={OutFlightNo4filter} placeholder="Enter Out Flight4" onChange={(e) => setOutFlightNo4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutAirlineName4FilterTemplate = () => {
        return <InputText id="OutAirlineName4" value={OutAirlineName4filter} placeholder="Enter Out Airline4" onChange={(e) => setOutAirlineName4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutDepartFrom4FilterTemplate = () => {
        return <InputText id="OutDepartFrom4" value={OutDepartFrom4filter} placeholder="Enter Out Depart4" onChange={(e) => setOutDepartFrom4filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const OutArriveAt4FilterTemplate = () => {
        return <InputText id="OutArriveAt4" value={OutArriveAt4filter} placeholder="Enter Out Arrive4" onChange={(e) => setOutArriveAt1filter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const TricoConfirmedFilterTemplate = () => {
        return <InputText id="TricoConfirmed" value={TricoConfirmfilter} placeholder="Enter Confirm Batch" onChange={(e) => setTricoConfirmfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const TicketBookedFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.TicketBookedfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.TicketBookedfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { TicketBooked: e.value });
                    e.preventDefault();
                }}
                placeholder="Select TicketBooked"
                className="p-column-filter"
            />
        );
    };
    
    const NeedAccomodationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.NeedAccomodationfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.NeedAccomodationfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { NeedAccomodation: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Accomodation"
                className="p-column-filter"
            />
        );
    };

    const OccupancyFilterTemplate = () => {
        return (
            <Dropdown value={lazyState.current.occupancy} options={Occupancys.Occupancy}
                optionLabel="name" showClear
                onChange={(e) => { lazyState.current.occupancy = (e.target.value ? e.target.value : null); getparticipantlist(isExportExcel, { Occupancy: e.value }); e.preventDefault() }} placeholder="Select Occupancy" className="p-column-filter" style={{ minWidth: '12rem' }} />
        );
    };

    const PresentFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Presentfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Presentfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { Present: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Present"
                className="p-column-filter"
            />
        );
    };

    const DayTwoPresentfilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.DayTwoPresentfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.DayTwoPresentfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { DayTwoPresentfilter: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Day Two Present"
                className="p-column-filter"
            />
        );
    };

    const IsArrivedFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.IsArrivedfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.IsArrivedfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { IsArrived: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Arrived Status"
                className="p-column-filter"
            />
        );
    };

    const IsCheckedInFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.IsCheckedInfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.IsCheckedInfilter = e.target.value ? e.target.value : null;
                    getparticipantlist(isExportExcel, { IsCheckedIn: e.value });
                    e.preventDefault();
                }}
                placeholder="Select CheckedIn"
                className="p-column-filter"
            />
        );
    };

    // const HotelFilterTemplate = () => {
    //     return <InputText id="Hotel" value={HotelFilter} placeholder="Enter Hotel" onChange={(e) => setHotelFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    // };


const HotelFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.HotelFilter}
                options={hotelList}
                itemTemplate={HotelFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => HotelFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                selectionLimit={3}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const HotelFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const HotelFilterBodyTemplate = (rowData) => {
        const hotel = rowData.hotelName;
        return (
            <div className="flex align-items-center gap-2">
                <span>{hotel}</span>
            </div>
        );
    };

    const HotelFilterClick = (e) => {
        lazyState.current.HotelFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { Hotel: e.value });
        e.preventDefault();
    }

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.StateFilter = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.CountryFilter = e.target.value ? e.target.value : null;
                        break;
                    case "registeredby":
                        lazyState.current.registeredby = e.target.value ? e.target.value : null;
                        break;
                    case "registeredbynumber":
                        lazyState.current.registeredbynumber = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo1":
                        lazyState.current.InFlightNo1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName1":
                        lazyState.current.InAirlineName1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom1":
                        lazyState.current.InDepartFrom1filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt1":
                        lazyState.current.InArriveAt1filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo2":
                        lazyState.current.InFlightNo2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName2":
                        lazyState.current.InAirlineName2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom2":
                        lazyState.current.InDepartFrom2filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt2":
                        lazyState.current.InArriveAt2filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo3":
                        lazyState.current.InFlightNo3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName3":
                        lazyState.current.InAirlineName3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom3":
                        lazyState.current.InDepartFrom3filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt3":
                        lazyState.current.InArriveAt3filter = e.target.value ? e.target.value : null;
                        break;

                    case "InFlightNo4":
                        lazyState.current.InFlightNo4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InAirlineName4":
                        lazyState.current.InAirlineName4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InDepartFrom4":
                        lazyState.current.InDepartFrom4filter = e.target.value ? e.target.value : null;
                        break;
                    case "InArriveAt4":
                        lazyState.current.InArriveAt4filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo1":
                        lazyState.current.OutFlightNo1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName1":
                        lazyState.current.OutAirlineName1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom1":
                        lazyState.current.OutDepartFrom1filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt1":
                        lazyState.current.OutArriveAt1filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo2":
                        lazyState.current.OutFlightNo2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName2":
                        lazyState.current.OutAirlineName2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom2":
                        lazyState.current.OutDepartFrom2filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt2":
                        lazyState.current.OutArriveAt2filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo3":
                        lazyState.current.OutFlightNo3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName3":
                        lazyState.current.OutAirlineName3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom3":
                        lazyState.current.OutDepartFrom3filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt3":
                        lazyState.current.OutArriveAt3filter = e.target.value ? e.target.value : null;
                        break;

                    case "OutFlightNo4":
                        lazyState.current.OutFlightNo4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutAirlineName4":
                        lazyState.current.OutAirlineName4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutDepartFrom4":
                        lazyState.current.OutDepartFrom4filter = e.target.value ? e.target.value : null;
                        break;
                    case "OutArriveAt4":
                        lazyState.current.OutArriveAt4filter = e.target.value ? e.target.value : null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = null;
                        break;
                    case "hospitalname":
                        lazyState.current.HospitalNameFilter = null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = null;
                        break;
                    case "state":
                        lazyState.current.StateFilter = null;
                        break;
                    case "country":
                        lazyState.current.CountryFilter = null;
                        break;
                    case "registeredby":
                        lazyState.current.registeredby = null;
                        break;
                    case "registeredbynumber":
                        lazyState.current.registeredbynumber = null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = null;
                        break;

                    case "InFlightNo1":
                        lazyState.current.InFlightNo1filter = null;
                        break;
                    case "InAirlineName1":
                        lazyState.current.InAirlineName1filter = null;
                        break;
                    case "InDepartFrom1":
                        lazyState.current.InDepartFrom1filter = null;
                        break;
                    case "InArriveAt1":
                        lazyState.current.InArriveAt1filter = null;
                        break;

                    case "InFlightNo2":
                        lazyState.current.InFlightNo2filter = null;
                        break;
                    case "InAirlineName2":
                        lazyState.current.InAirlineName2filter = null;
                        break;
                    case "InDepartFrom2":
                        lazyState.current.InDepartFrom2filter = null;
                        break;
                    case "InArriveAt2":
                        lazyState.current.InArriveAt2filter = null;
                        break;

                    case "InFlightNo3":
                        lazyState.current.InFlightNo3filter = null;
                        break;
                    case "InAirlineName3":
                        lazyState.current.InAirlineName3filter = null;
                        break;
                    case "InDepartFrom3":
                        lazyState.current.InDepartFrom3filter = null;
                        break;
                    case "InArriveAt3":
                        lazyState.current.InArriveAt3filter = null;
                        break;

                    case "InFlightNo4":
                        lazyState.current.InFlightNo4filter = null;
                        break;
                    case "InAirlineName4":
                        lazyState.current.InAirlineName4filter = null;
                        break;
                    case "InDepartFrom4":
                        lazyState.current.InDepartFrom4filter = null;
                        break;
                    case "InArriveAt4":
                        lazyState.current.InArriveAt4filter = null;
                        break;

                    case "OutFlightNo1":
                        lazyState.current.OutFlightNo1filter = null;
                        break;
                    case "OutAirlineName1":
                        lazyState.current.OutAirlineName1filter = null;
                        break;
                    case "OutDepartFrom1":
                        lazyState.current.OutDepartFrom1filter = null;
                        break;
                    case "OutArriveAt1":
                        lazyState.current.OutArriveAt1filter = null;
                        break;

                    case "OutFlightNo2":
                        lazyState.current.OutFlightNo2filter = null;
                        break;
                    case "OutAirlineName2":
                        lazyState.current.OutAirlineName2filter = null;
                        break;
                    case "OutDepartFrom2":
                        lazyState.current.OutDepartFrom2filter = null;
                        break;
                    case "OutArriveAt2":
                        lazyState.current.OutArriveAt2filter = null;
                        break;

                    case "OutFlightNo3":
                        lazyState.current.OutFlightNo3filter = null;
                        break;
                    case "OutAirlineName3":
                        lazyState.current.OutAirlineName3filter = null;
                        break;
                    case "OutDepartFrom3":
                        lazyState.current.OutDepartFrom3filter = null;
                        break;
                    case "OutArriveAt3":
                        lazyState.current.OutArriveAt3filter = null;
                        break;

                    case "OutFlightNo4":
                        lazyState.current.OutFlightNo4filter = null;
                        break;
                    case "OutAirlineName4":
                        lazyState.current.OutAirlineName4filter = null;
                        break;
                    case "OutDepartFrom4":
                        lazyState.current.OutDepartFrom4filter = null;
                        break;
                    case "OutArriveAt4":
                        lazyState.current.OutArriveAt4filter = null;
                        break;
                    case "TricoConfirmed":
                        lazyState.current.TricoConfirmedfilter = null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = null;
                        break;
                }
                getparticipantlist(isExportExcel, lazyState.current);
                setIsLoading(false);
            }
        }
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    const ViewAttendees = (rowData) => {
        return (
            <AccessControl allowedPermissions={["view:viewallattendees"]}>
                <Link
                    className="linkcolors"
                    id="viewattendee"
                    to="#"
                    onClick={(e) => {
                        history.push("/home/viewparticipant/" + rowData.id);
                    }}
                >
                    {rowData.fullName}
                </Link>
                {/* <span className="font-bold">{rowData.fullName}</span>; */}
            </AccessControl>
        );
    };

    const exportExcel = () => {
        getparticipantlist(true);
    };

    const removeFilter = () => {
        lazyState.current.registrationStatus = null;
        lazyState.current.CompanyFilter = null;
        setcompanyNameFilter(null);
        lazyState.current.ParticipantNameFilter = null;
        setfullName(null);
        lazyState.current.BoardingCityFilter = null;
        lazyState.current.CityFilter = null;
        lazyState.current.CountryFilter = null;
        lazyState.current.Designationfilter = null;
        lazyState.current.HospitalNameFilter = null;
        sethospitalNamefilter(null);
        lazyState.current.EmailFilter = null;
        setFilterEmail(null);
        lazyState.current.MobileNoFilter = null;
        setFilterMobileNo(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.StateFilter = null;
        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.registeredby = null;
        setRegisteredName(null);
        lazyState.current.registeredbynumber = null;
        setRegisteredByNumber(null);
        lazyState.current.serialnumber = null;
        setSerialNumber(null);
        lazyState.current.zonefilter = null;
        lazyState.current.inbatchfilter = null;
        lazyState.current.outbatchfilter = null;
        lazyState.current.inflightstatusfilter = null;
        lazyState.current.outflightstatusfilter = null;

        setInFlightNo1filter(null);
        setInAirlineName1filter(null);
        setInDepartFrom1filter(null);
        setInArriveAt1filter(null);
        lazyState.current.InFlightNo1filter = null;
        lazyState.current.InAirlineName1filter = null;
        lazyState.current.InDepartFrom1filter = null;
        lazyState.current.InArriveAt1filter = null;

        setInFlightNo2filter(null);
        setInAirlineName2filter(null);
        setInDepartFrom2filter(null);
        setInArriveAt2filter(null);
        lazyState.current.InFlightNo2filter = null;
        lazyState.current.InAirlineName2filter = null;
        lazyState.current.InDepartFrom2filter = null;
        lazyState.current.InArriveAt2filter = null;

        setInFlightNo3filter(null);
        setInAirlineName3filter(null);
        setInDepartFrom3filter(null);
        setInArriveAt3filter(null);
        lazyState.current.InFlightNo3filter = null;
        lazyState.current.InAirlineName3filter = null;
        lazyState.current.InDepartFrom3filter = null;
        lazyState.current.InArriveAt3filter = null;

        setInFlightNo4filter(null);
        setInAirlineName4filter(null);
        setInDepartFrom4filter(null);
        setInArriveAt4filter(null);
        lazyState.current.InFlightNo4filter = null;
        lazyState.current.InAirlineName4filter = null;
        lazyState.current.InDepartFrom4filter = null;
        lazyState.current.InArriveAt4filter = null;

        setOutFlightNo1filter(null);
        setOutAirlineName1filter(null);
        setOutDepartFrom1filter(null);
        setOutArriveAt1filter(null);
        lazyState.current.OutFlightNo1filter = null;
        lazyState.current.OutAirlineName1filter = null;
        lazyState.current.OutDepartFrom1filter = null;
        lazyState.current.OutArriveAt1filter = null;

        setOutFlightNo2filter(null);
        setOutAirlineName2filter(null);
        setOutDepartFrom2filter(null);
        setOutArriveAt2filter(null);
        lazyState.current.OutFlightNo2filter = null;
        lazyState.current.OutAirlineName2filter = null;
        lazyState.current.OutDepartFrom2filter = null;
        lazyState.current.OutArriveAt2filter = null;

        setOutFlightNo3filter(null);
        setOutAirlineName3filter(null);
        setOutDepartFrom3filter(null);
        setOutArriveAt3filter(null);
        lazyState.current.OutFlightNo3filter = null;
        lazyState.current.OutAirlineName3filter = null;
        lazyState.current.OutDepartFrom3filter = null;
        lazyState.current.OutArriveAt3filter = null;

        setOutFlightNo4filter(null);
        setOutAirlineName4filter(null);
        setOutDepartFrom4filter(null);
        setOutArriveAt4filter(null);
        lazyState.current.OutFlightNo4filter = null;
        lazyState.current.OutAirlineName4filter = null;
        lazyState.current.OutDepartFrom4filter = null;
        lazyState.current.OutArriveAt4filter = null;

        setTricoConfirmfilter(null);
        lazyState.current.TricoConfirmedfilter = null;
        lazyState.current.TicketBookedfilter = null;
        lazyState.current.IsArrivedfilter = null;
        lazyState.current.IsCheckedInfilter = null;
        lazyState.current.Presentfilter = null;
        lazyState.current.DayTwoPresentfilter = null;
        lazyState.current.NeedAccomodationfilter = null;

        lazyState.current.HotelFilter = null;
        setHotelFilter(null);

        lazyState.current.occupancy = null;

        getparticipantlist(isExportExcel);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:viewallattendees"]}>
                    <ToggleButton className="mr-2" checked={NameFrozen} onChange={(e) => setNameFrozen(e.value)} onIcon="pi pi-lock" offIcon="pi pi-lock-open" onLabel="Frozed Name" offLabel="Freeze Name" />
                    <Button
                        disabled={
                            lazyState.current.CompanyFilter == null &&
                                lazyState.current.ParticipantNameFilter == null &&
                                lazyState.current.BoardingCityFilter == null &&
                                lazyState.current.CityFilter == null &&
                                lazyState.current.CountryFilter == null &&
                                lazyState.current.Designationfilter == null &&
                                lazyState.current.HospitalNameFilter == null &&
                                lazyState.current.EmailFilter == null &&
                                lazyState.current.MobileNoFilter == null &&
                                lazyState.current.zonefilter == null &&
                                lazyState.current.Genderfilter == null &&
                                lazyState.current.StateFilter == null &&
                                lazyState.current.Toeventcityfilter == null &&
                                lazyState.current.serialnumber == null &&
                                lazyState.current.inbatchfilter == null &&
                                lazyState.current.outbatchfilter == null &&
                                lazyState.current.inflightstatusfilter == null &&
                                lazyState.current.outflightstatusfilter == null &&
                                lazyState.current.InFlightNo1filter == null &&
                                lazyState.current.InAirlineName1filter == null &&
                                lazyState.current.InDepartFrom1filter == null &&
                                lazyState.current.InArriveAt1filter == null &&
                                lazyState.current.InFlightNo2filter == null &&
                                lazyState.current.InAirlineName2filter == null &&
                                lazyState.current.InDepartFrom2filter == null &&
                                lazyState.current.InArriveAt2filter == null &&
                                lazyState.current.InFlightNo3filter == null &&
                                lazyState.current.InAirlineName3filter == null &&
                                lazyState.current.InDepartFrom3filter == null &&
                                lazyState.current.InArriveAt3filter == null &&
                                lazyState.current.InFlightNo4filter == null &&
                                lazyState.current.InAirlineName4filter == null &&
                                lazyState.current.InDepartFrom4filter == null &&
                                lazyState.current.InArriveAt4filter == null &&
                                lazyState.current.OutFlightNo1filter == null &&
                                lazyState.current.OutAirlineName1filter == null &&
                                lazyState.current.OutDepartFrom1filter == null &&
                                lazyState.current.OutArriveAt1filter == null &&
                                lazyState.current.OutFlightNo2filter == null &&
                                lazyState.current.OutAirlineName2filter == null &&
                                lazyState.current.OutDepartFrom2filter == null &&
                                lazyState.current.OutArriveAt2filter == null &&
                                lazyState.current.OutFlightNo3filter == null &&
                                lazyState.current.OutAirlineName3filter == null &&
                                lazyState.current.OutDepartFrom3filter == null &&
                                lazyState.current.OutArriveAt3filter == null &&
                                lazyState.current.OutFlightNo4filter == null &&
                                lazyState.current.OutAirlineName4filter == null &&
                                lazyState.current.OutDepartFrom4filter == null &&
                                lazyState.current.OutArriveAt4filter == null &&
                                lazyState.current.TricoConfirmedfilter == null &&
                                lazyState.current.TicketBookedfilter == null &&
                                lazyState.current.Presentfilter == null &&
                                lazyState.current.DayTwoPresentfilter == null &&
                                lazyState.current.IsArrivedfilter == null &&
                                lazyState.current.IsCheckedInfilter == null &&
                                lazyState.current.NeedAccomodationfilter == null &&
                                lazyState.current.HotelFilter == null &&
                                lazyState.current.Fromeventcityfilter == null &&
                                lazyState.current.registrationStatus == null &&
                                lazyState.current.registeredby == null &&
                                lazyState.current.occupancy == null &&
                                lazyState.current.registeredbynumber == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="p-button-outlined mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                </AccessControl>
                {/* <div className="my-2">{header}</div> */}
                <AccessControl allowedPermissions={["export:viewallattendees"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            exportExcel();
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "ViewAllAttendee" } })}>
                    {" "}
                    <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" || usrDetails?.role == "MR" || usrDetails?.role == "SUBMR" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };
    //#endregion

    //Update Registration Status of participant

    const UpdateRegistrationStatus = (id, status, enable) => {
        setIsLoading(true);
        const url = "/api/Participant/UpdateregistrationStatus";
        const data = {
            id: id.toString(),
            registrationstatus: status,
            IsEnabled: enable,
            UserId: Userid.toString(),
            ModifiedBy: UserName,
        };
        axios
            .put(url, data)
            .then((res) => {
                setIsLoading(false);
                getparticipantlist(isExportExcel, lazyState.current);
                if (status == "APPROVED") {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attendee Approved", life: 3000 });
                } else {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attendee Rejected", life: 3000 });
                }
                setid([]);
                setuserObj([]);
                setActionDisable(true);
                setselecteddata(null);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // const UpdateRegistrationStatus = (id, status) => {
    //     setIsLoading(true);
    //     if (status == "APPROVED") {
    //         axios.put(`/api/Participant/UpdateregistrationStatus/${id}?registrationstatus=${status}&IsEnabled=${true}&UserId=${Userid}`)
    //             .then(res => {
    //                 setIsLoading(false);
    //                 getparticipantlist(isExportExcel, lazyState.current);
    //                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Approved', life: 3000 });
    //                 setid([]);
    //                 setActionDisable(true);
    //                 setselecteddata(null);
    //             }).catch((error) => {
    //                 setIsLoading(false);
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
    //             });
    //     }
    //     else {
    //         axios.put(`/api/Participant/UpdateregistrationStatus/${id}?registrationstatus=${status}&IsEnabled=${false}&UserId=${Userid}`)
    //             .then(res => {
    //                 setIsLoading(false);
    //                 getparticipantlist(isExportExcel, lazyState.current);
    //                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attendee Rejected', life: 3000 });
    //                 setid([]);
    //                 setActionDisable(true);
    //                 setselecteddata(null);
    //             }).catch((error) => {
    //                 setIsLoading(false);
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
    //             });
    //     }
    // };

    // get config data
    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setUrlData(data[0].QrCodeUrl);
                setApiHeaders(data[1]);
                setWhatsAppUrlData(data[5].QrCodeWhatsAppUrl);
                setWhatsAppStatus(data[7].EnableWhatsApp);
                setSeemamamNo(data[8].SeemaMam);
            });
    };

    const generateQRCode = (event) => {
        participantQRCode.qrId = event.id;
        participantQRCode.url = UrlData + event.id;
        // participantQRCode.url = 'http://localhost:3000/#/home/QrCodeScan';
        QrCodes.push(participantQRCode);
    };

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                // generateQRCode(i.id);
                return i.id;
            })
        );
        setuserObj(
            e.data.map((i, k) => {
                generateQRCode(i);
                return i;
            })
        );
        setUserid(
            e.data.map((i, k) => {
                return i.userid;
            })
        );
        setMobileNo(
            e.data.map((i, k) => {
                return i.mobileNo;
            })
        );
        for (const dataobj of e.data) {
            // User.push(dataobj);
            if (User.filter((x) => x.firstname == dataobj.firstname).length == 0) {
                User.push(dataobj.userid);
            }
        }
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            var removeuseridfromdata = Userid.indexOf(event.data.userid);
            var removeMobileNofromdata = MobileNo.indexOf(event.data.mobileNo);
            var removedatafromobjbyid = userObj.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            Userid.splice(removeuseridfromdata, 1);
            MobileNo.splice(removeMobileNofromdata, 1);
            userObj.splice(removedatafromobjbyid, 1);
            QrCodes.splice(removeidfromdata, 1);
            User.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            var removeuseridfromarray = Userid.indexOf(event.data.userid);
            var removeMobileNofromarray = MobileNo.indexOf(event.data.mobileNo);
            var removedatafromobjarray = userObj.indexOf(event.data.id);
            id.splice(removefromarray, 1);
            Userid.splice(removeuseridfromarray, 1);
            userObj.splice(removedatafromobjarray, 1);
            MobileNo.splice(removeMobileNofromarray, 1);
            User.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        setuserObj(
            e.data.map((i, k) => {
                return i;
            })
        );
        setUserid(
            e.data.map((i, k) => {
                return i.userid;
            })
        );
        setQRCodes([]);
        id.splice(0, id.length);
        Userid.splice(0, Userid.length);
        MobileNo.splice(0, MobileNo.length);
        userObj.splice(0, userObj.length);
        User.splice(0, User.length);
    };

    const onRowSelect = (event) => {
        User.push(event.data.userid);
        setActionDisable(false);
        setuserObj((ing) => [...ing, event.data]);
        setid((ing) => [...ing, event.data.id]);
        setUserid((ing) => [...ing, event.data.userid]);
        setMobileNo((ing) => [...ing, event.data.mobileNo]);
        generateQRCode(event.data);
    };

    //#region Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <>
                    <h4 className="headerfont m-auto mr-2">Count : {totalRecords}</h4>
                </>
                <AccessControl allowedPermissions={["approve:viewnewattendees"]}>
                    <Button
                        disabled={ActionDisable}
                        label="Approve"
                        icon="fa fa-check"
                        className="mr-2"
                        tooltip="Approve"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={(e) => {
                            QrGenerationLoop();
                            UpdateRegistrationStatus(id, "APPROVED", true);
                        }}
                        outlined
                    />
                </AccessControl>
                <AccessControl allowedPermissions={["reject:viewnewattendees"]}>
                    <Button disabled={ActionDisable} label="Reject" icon="fa fa-times" tooltip="Reject" tooltipOptions={{ position: "bottom", className: "showtooltip" }} onClick={(e) => UpdateRegistrationStatus(id, "REJECTED", false)} outlined />
                </AccessControl>
            </div>
        );
    };

    const QrGenerationLoop = () => {
        qrData = [];
        Object.values(userObj).forEach((value) => {
            let participantQRCode = {
                id: "",
                ImageUrl: "",
                ImageName: "",
                MobileNo: "",
                UserName: "",
                Url: "",
                Registeredby: "",
                companyContactPerson: "",
                userId: "",
                password: "",
            };
            // participantQRCode.id = value;
            const qrCodeURL = document.getElementById(`qrCodeEl${value.id}`).toDataURL("image/png").replace("image/png", "image/octet-stream");
            participantQRCode.id = value.id;
            participantQRCode.ImageUrl = qrCodeURL.substring(31);
            participantQRCode.ImageName = "QRCODE_" + value.id + ".jpg";
            participantQRCode.MobileNo = value.mobileNo;
            participantQRCode.UserName = value.fullName;
            participantQRCode.Url = UrlData + value.id;
            participantQRCode.Registeredby = value.companyphone;
            participantQRCode.companyContactPerson = value.companyContactPerson;
            participantQRCode.userId = value.email;
            participantQRCode.password = value.password;
            qrData.push(participantQRCode);
        });
        SaveQRCode();
        if (WhatsappStatus == true) {
            //SendQrWhatsApp();
            //SendQrWhatsAppToSeemaMam();
            //SendQrWhatsAppToRegisteredBy();

            SendApprovalWithQrWhatsApp();
            SendApprovalWithQrWhatsAppToSeemaMam();
            SendApprovalWithQrWhatsAppToRegisteredBy();

            const interval = setInterval(() => {
                if (WhatsAppMessageStatus.current.Doctor != null && WhatsAppMessageStatus.current.FieldPerson != null && WhatsAppMessageStatus.current.Admin != null) {
                    if (WhatsAppMessageStatus.current.Doctor == 200 && WhatsAppMessageStatus.current.FieldPerson == 200 && WhatsAppMessageStatus.current.Admin == 200) {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Successfully Sent The QR Code On WhatsApp", life: 3000 });
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong In Sending WhatsApp!", life: 3000 });
                    }
                    clearInterval(interval);
                }
            }, 100);
            WhatsAppMessageStatus.current.Doctor = null;
            WhatsAppMessageStatus.current.FieldPerson = null;
            WhatsAppMessageStatus.current.Admin = null;
        }
    };

    const SaveQRCode = () => {
        const url = "/api/Participant/SaveQrCode";
        const data = qrData;
        axios
            .post(url, data)
            .then(() => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "QR GENERATED SUCCESSFULLY", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };
    //#endregion

    //#region Whats App Qr Send api
    // const SendQrWhatsApp = () => {
    //     qrData.forEach((element) => {
    //         var data = {
    //             messaging_product: "whatsapp",
    //             recipient_type: "individual",
    //             to: element.MobileNo,
    //             type: "template",
    //             template: {
    //                 name: "trico_qr_template",
    //                 language: {
    //                     code: "en",
    //                 },
    //                 components: [
    //                     {
    //                         type: "header",
    //                         parameters: [
    //                             {
    //                                 type: "image",
    //                                 image: {
    //                                     link: WhatsAppUrlData + element.ImageName,
    //                                 },
    //                             },
    //                         ],
    //                     },
    //                     {
    //                         type: "body",
    //                         parameters: [
    //                             {
    //                                 type: "text",
    //                                 text: element.UserName,
    //                             },
    //                         ],
    //                     },
    //                 ],
    //             },
    //         };
    //         // axios
    //         //     .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
    //         //     .then((res) => {
    //         //         WhatsAppMessageStatus.current.Doctor = res.status;
    //         //         WhatsAppLog(data, element?.id);
    //         //     })
    //         //     .catch((error) => {
    //         //         WhatsAppMessageStatus.current.Doctor = error?.response?.status;
    //         //     });
    //     });
    // };

    // const SendQrWhatsAppToRegisteredBy = () => {
    //     qrData.forEach((element) => {
    //         if (element.Registeredby != null) {
    //             var data = {
    //                 messaging_product: "whatsapp",
    //                 recipient_type: "individual",
    //                 to: element.Registeredby,
    //                 type: "template",
    //                 template: {
    //                     name: "trico_qr_template_mrcompany",
    //                     language: {
    //                         code: "en",
    //                     },
    //                     components: [
    //                         {
    //                             type: "header",
    //                             parameters: [
    //                                 {
    //                                     type: "image",
    //                                     image: {
    //                                         link: WhatsAppUrlData + element.ImageName,
    //                                     },
    //                                 },
    //                             ],
    //                         },
    //                         {
    //                             type: "body",
    //                             parameters: [
    //                                 {
    //                                     type: "text",
    //                                     text: element.companyContactPerson,
    //                                 },
    //                                 {
    //                                     type: "text",
    //                                     text: element.UserName,
    //                                 },
    //                             ],
    //                         },
    //                     ],
    //                 },
    //             };
    //             axios
    //                 .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
    //                 .then((res) => {
    //                     WhatsAppMessageStatus.current.FieldPerson = res.status;
    //                     WhatsAppLog(data, element?.id);
    //                 })
    //                 .catch((error) => {
    //                     WhatsAppMessageStatus.current.FieldPerson = error?.response?.status;
    //                 });
    //         }
    //     });
    // };

    // const SendQrWhatsAppToSeemaMam = () => {
    //     qrData.forEach((element) => {
    //         var data = {
    //             messaging_product: "whatsapp",
    //             recipient_type: "individual",
    //             to: SeemamamNo,
    //             type: "template",
    //             template: {
    //                 name: "trico_qr_template_apexadmin",
    //                 language: {
    //                     code: "en",
    //                 },
    //                 components: [
    //                     {
    //                         type: "header",
    //                         parameters: [
    //                             {
    //                                 type: "image",
    //                                 image: {
    //                                     link: WhatsAppUrlData + element.ImageName,
    //                                 },
    //                             },
    //                         ],
    //                     },
    //                     {
    //                         type: "body",
    //                         parameters: [
    //                             {
    //                                 type: "text",
    //                                 text: element.UserName,
    //                             },
    //                         ],
    //                     },
    //                 ],
    //             },
    //         };
    //         axios
    //             .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
    //             .then((res) => {
    //                 WhatsAppMessageStatus.current.Admin = res.status;
    //                 WhatsAppLog(data, element?.id);
    //             })
    //             .catch((error) => {
    //                 WhatsAppMessageStatus.current.Admin = error?.response?.status;
    //             });
    //     });
    // };

    const SendApprovalWithQrWhatsApp = () => {
        qrData.forEach((element) => {
            var data = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: element.MobileNo,
                type: "template",
                template: {
                    name: "trico_approve_status_template_utl",
                    language: {
                        code: "en",
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "image",
                                    image: {
                                        link: WhatsAppUrlData + element.ImageName,
                                    },
                                },
                            ],
                        },
                        {
                            type: "body",
                            parameters: [
                                {
                                    type: "text",
                                    text: element.UserName.trim(),
                                },
                                {
                                    type: "text",
                                    text: "https://www.tricoevent.com/",
                                },
                                {
                                    type: "text",
                                    text: element.userId,
                                },
                                {
                                    type: "text",
                                    text: element.password,
                                },
                            ],
                        },
                    ],
                },
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                .then((res) => {
                    WhatsAppMessageStatus.current.Doctor = res.status;
                    WhatsAppLog(data, element?.id);
                })
                .catch((error) => {
                    WhatsAppMessageStatus.current.Doctor = error?.response?.status;
                });
        });
    };
    
    const SendApprovalWithQrWhatsAppToSeemaMam = () => {
        qrData.forEach((element) => {
            var data = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: SeemamamNo,
                type: "template",
                template: {
                    name: "trico_approve_status_template_utl",
                    language: {
                        code: "en",
                    },
                    components: [
                        {
                            type: "header",
                            parameters: [
                                {
                                    type: "image",
                                    image: {
                                        link: WhatsAppUrlData + element.ImageName,
                                    },
                                },
                            ],
                        },
                        {
                            type: "body",
                            parameters: [
                                {
                                    type: "text",
                                    text: element.UserName.trim(),
                                },
                                {
                                    type: "text",
                                    text: "https://www.tricoevent.com/",
                                },
                                {
                                    type: "text",
                                    text: element.userId,
                                },
                                {
                                    type: "text",
                                    text: element.password,
                                },
                            ],
                        },
                    ],
                },
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                .then((res) => {
                    WhatsAppMessageStatus.current.Doctor = res.status;
                    WhatsAppLog(data, element?.id);
                })
                .catch((error) => {
                    WhatsAppMessageStatus.current.Doctor = error?.response?.status;
                });
        });
    };

    const SendApprovalWithQrWhatsAppToRegisteredBy = () => {
        qrData.forEach((element) => {
            if (element.Registeredby != null) {
                var data = {
                    messaging_product: "whatsapp",
                    recipient_type: "individual",
                    to: element.Registeredby,
                    type: "template",
                    template: {
                        name: "trico_approve_status_template_utl",
                        language: {
                            code: "en",
                        },
                        components: [
                            {
                                type: "header",
                                parameters: [
                                    {
                                        type: "image",
                                        image: {
                                            link: WhatsAppUrlData + element.ImageName,
                                        },
                                    },
                                ],
                            },
                            {
                                type: "body",
                                parameters: [
                                    {
                                        type: "text",
                                        text: element.UserName.trim(),
                                    },
                                    {
                                        type: "text",
                                        text: "https://www.tricoevent.com/",
                                    },
                                    {
                                        type: "text",
                                        text: element.userId,
                                    },
                                    {
                                        type: "text",
                                        text: element.password,
                                    },
                                ],
                            },
                        ],
                    },
                };
                axios
                    .post("https://graph.facebook.com/v17.0/177696368765013/messages", data, ApiHeaders)
                    .then((res) => {
                        WhatsAppMessageStatus.current.FieldPerson = res.status;
                        WhatsAppLog(data, element?.id);
                    })
                    .catch((error) => {
                        WhatsAppMessageStatus.current.FieldPerson = error?.response?.status;
                    });
            }
        });
    };

    const WhatsAppLog = (Apidata, id) => {
        const url = "/api/Participant/WhatsAppLog";
        const data = {
            Number: Apidata?.to?.toString(),
            Template: Apidata.template.name,
            SendBy: UserName,
            AttendeeId: id.toString(),
            Screen: "VIEW-ALL-ATTENDEES",
        };
        axios
            .post(url, data)
            .then((res) => { })
            .catch((error) => {
                console.log(error);
            });
    };
    //#endregion

    const DownloadFile = (ticketpath) => {
        setIsLoading(true);
        const headers = {
            "Accept-Type": "application/octet-stream",
            Authorization: "Bearer " + token,
        };
        axios
            .get("/api/Participant/files?filename=" + ticketpath, {
                headers: headers,
                credentials: "include",
                responseType: "blob",
            })
            .then((Response) => {
                setIsLoading(false);
                var data = new Blob([Response.data], { type: "application/pdf" });
                var csvURL = window.URL.createObjectURL(data);
                var filename = ticketpath.split("/").pop();
                window.open(csvURL, filename);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const attachfiletemplate = (rowData) => {
        // Check if there are files
        if (!rowData.participantFileData || rowData.participantFileData.length === 0) {
            // If no files, return null to hide the column
            return null;
        }

        return (
            <>
                <i className={"fa fa-paperclip p-button-rounded p-button-outlined p-button-secondary justify-content-center p-button-outlined-attachment tooltip-button-" + rowData.id} style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
                <Tooltip target={".tooltip-button-" + rowData.id} autoHide={false} className="settooltip">
                    <div className="flex align-items-start" style={{ flexDirection: "column" }}>
                        {rowData.participantFileData.map((item) => (
                            <div key={item.id}>
                                <span style={{ minWidth: "12rem", cursor: "pointer", marginBottom: "0.2rem" }} onClick={(e) => DownloadFile(item.ticketPath)} className="fa fa-download">{`   ${item.title}`}</span>
                            </div>
                        ))}
                    </div>
                </Tooltip>
            </>
        );
    };

    // const scrollHeight = screenHeight

    return (
        <AccessControl allowedPermissions={["list:viewallattendees"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-users mr-2"></i>View All Attendees
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    selectionMode={"checkbox"}
                                    onAllRowsSelect={onAllRowSelect}
                                    scrollable
                                    // scrollHeight="calc(100vh - 250px)"
                                    scrollHeight="calc(100vh - 250px)"
                                    // scrollHeight={`${scrollHeight}px`}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    size="small"
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    selection={selecteddata}
                                    onSelectionChange={(e) => {
                                        setselecteddata(e.value);
                                    }}
                                    responsiveLayout="scroll"
                                    filterDisplay={"row"}
                                >
                                    <Column body={ViewAttendeeIcon} alignFrozen="left" frozen={NameFrozen} exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column body={attachfiletemplate} frozen={NameFrozen} headerStyle={{ width: "0%", minWidth: "1rem" }} alignFrozen="left" exportable={true}></Column>
                                    <Column selectionMode="multiple" className={usrDetails.role == "MR" ? "hidden" : ""} alignFrozen="left" frozen={NameFrozen} headerStyle={{ width: "1%", minWidth: "1rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen={NameFrozen} headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column
                                        body={ViewAttendees}
                                        alignFrozen="left"
                                        frozen={NameFrozen}
                                        sortField="FullName"
                                        field="fullName"
                                        header="Name"
                                        sortable
                                        headerStyle={{ width: "15%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={UserFilterTemplate}
                                        showClearButton
                                    ></Column>
                                    <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} showClearButton></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} showClearButton></Column>
                                    <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate}></Column>
                                    <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No." sortable style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    <Column
                                        field="registrationStatus"
                                        sortField="RegistrationStatus"
                                        header="Registrationstatus"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={RegistrationStatusTemplate}
                                    ></Column>
                                    <Column field="gender" header="Gender" sortField="Gender" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={GenderFilterTemplate}></Column>
                                    <Column field="designation" header="Designation" sortField="Designation" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem', overflowWrap: "anywhere" }} showFilterMenu={false} filterElement={DesignationFilterTemplate} body={DesignationFilterBodyTemplate} filterField="designation" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="zone" header="Zone" sortField="Zone" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem' }} showFilterMenu={false} filterElement={ZoneFilterTemplate} body={ZoneFilterBodyTemplate} filterField="Zone" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="countryname" header="Country" sortField="Countryname" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={CountryFilterTemplate} body={CountryFilterBodyTemplate} filterField="Country" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="statename" header="State" sortField="Statename" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={StateFilterTemplate} body={StateFilterBodyTemplate} filterField="State" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="cityname" header="City" sortField="cityname" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={CityFilterTemplate} body={CityFilterBodyTemplate} filterField="City" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="boardingcityname" header="Boarding City" sortField="boardingcityname" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={BoardingCityFilterTemplate} body={BoardingCityFilterBodyTemplate} filterField="BoardingCity" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="travelmode_Inbound" header="Travel In Bound" sortField="Travelmode_Inbound" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={ToEventCityTemplate}></Column>
                                    <Column
                                        field="travelmode_Outbound"
                                        header="Travel Out Bound"
                                        sortField="Travelmode_Outbound"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={FromEventCityTemplate}
                                    ></Column>
                                    <Column field="inBatchBooking" header="In Batch" sortField="inBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={InBatchFilterTemplate} body={InBatchFilterBodyTemplate} filterField="InBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column
                                        field="inFlightBookingStatus"
                                        sortField="InFlightBookingStatus"
                                        sortable
                                        header="InFlight Booking Status"
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={InFlightBookingFilterTemplate}
                                    ></Column>
                                    <Column field="inFlightNo1" sortField="InFlightNo1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Flight No" filter showFilterMenu={false} filterElement={InFlightNo1FilterTemplate}></Column>
                                    <Column field="inAirlineName1" sortField="InAirlineName1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Airline" filter showFilterMenu={false} filterElement={InAirlineName1FilterTemplate}></Column>
                                    <Column field="inDepartFrom1" sortField="InDepartFrom1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom1FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime1"
                                        sortField="InDepartureDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime1 ? moment(rowData.inDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt1" sortField="InArriveAt1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF1 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt1FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime1"
                                        sortField="InArrivalDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime1 ? moment(rowData.inArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo2" sortField="InFlightNo2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Flight No" filter showFilterMenu={false} filterElement={InFlightNo2FilterTemplate}></Column>
                                    <Column field="inAirlineName2" sortField="InAirlineName2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Airline" filter showFilterMenu={false} filterElement={InAirlineName2FilterTemplate}></Column>
                                    <Column field="inDepartFrom2" sortField="InDepartFrom2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom2FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime2"
                                        sortField="InDepartureDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime2 ? moment(rowData.inDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt2" sortField="InArriveAt2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF2 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt2FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime2"
                                        sortField="InArrivalDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime2 ? moment(rowData.inArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo3" sortField="InFlightNo3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Flight No" filter showFilterMenu={false} filterElement={InFlightNo3FilterTemplate}></Column>
                                    <Column field="inAirlineName3" sortField="InAirlineName3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Airline" filter showFilterMenu={false} filterElement={InAirlineName3FilterTemplate}></Column>
                                    <Column field="inDepartFrom3" sortField="InDepartFrom3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom3FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime3"
                                        sortField="InDepartureDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime3 ? moment(rowData.inDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt3" sortField="InArriveAt3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF3 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt3FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime3"
                                        sortField="InArrivalDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime3 ? moment(rowData.inArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="inFlightNo4" sortField="InFlightNo4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Flight No" filter showFilterMenu={false} filterElement={InFlightNo4FilterTemplate}></Column>
                                    <Column field="inAirlineName4" sortField="InAirlineName4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Airline" filter showFilterMenu={false} filterElement={InAirlineName4FilterTemplate}></Column>
                                    <Column field="inDepartFrom4" sortField="InDepartFrom4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Depart From" filter showFilterMenu={false} filterElement={InDepartFrom4FilterTemplate}></Column>
                                    <Column
                                        field="inDepartureDateTime4"
                                        sortField="InDepartureDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inDepartureDateTime4 ? moment(rowData.inDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Departure DateTime"
                                    ></Column>
                                    <Column field="inArriveAt4" sortField="InArriveAt4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="INF4 Arrive At" filter showFilterMenu={false} filterElement={InArriveAt4FilterTemplate}></Column>
                                    <Column
                                        field="inArrivalDateTime4"
                                        sortField="InArrivalDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.inArrivalDateTime4 ? moment(rowData.inArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="INF4 Arrival DateTime"
                                    ></Column>
                                    <Column field="outBatchBooking" header="Out Batch" sortField="outBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={OutBatchFilterTemplate} body={OutBatchFilterBodyTemplate} filterField="OutBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column
                                        field="outFlightBookingStatus"
                                        sortField="OutFlightBookingStatus"
                                        sortable
                                        header="OutFlight Booking Status"
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutFlightBookingFilterTemplate}
                                    ></Column>
                                    <Column field="outFlightNo1" sortField="OutFlightNo1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo1FilterTemplate}></Column>
                                    <Column field="outAirlineName1" sortField="OutAirlineName1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Airline" filter showFilterMenu={false} filterElement={OutAirlineName1FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom1"
                                        sortField="OutDepartFrom1"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom1FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime1"
                                        sortField="OutDepartureDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime1 ? moment(rowData.outDepartureDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt1" sortField="OutArriveAt1" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF1 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt1FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime1"
                                        sortField="OutArrivalDateTime1"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime1 ? moment(rowData.outArrivalDateTime1).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF1 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo2" sortField="OutFlightNo2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo2FilterTemplate}></Column>
                                    <Column field="outAirlineName2" sortField="OutAirlineName2" sortable headerStyle={{ width: "20%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Airline" filter showFilterMenu={false} filterElement={OutAirlineName2FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom2"
                                        sortField="OutDepartFrom2"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom2FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime2"
                                        sortField="OutDepartureDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime2 ? moment(rowData.outDepartureDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt2" sortField="OutArriveAt2" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF2 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt2FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime2"
                                        sortField="OutArrivalDateTime2"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime2 ? moment(rowData.outArrivalDateTime2).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF2 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo3" sortField="OutFlightNo3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo3FilterTemplate}></Column>
                                    <Column field="outAirlineName3" sortField="OutAirlineName3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Airline" filter showFilterMenu={false} filterElement={OutAirlineName3FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom3"
                                        sortField="OutDepartFrom3"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom3FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime3"
                                        sortField="OutDepartureDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime3 ? moment(rowData.outDepartureDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt3" sortField="OutArriveAt3" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF3 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt3FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime3"
                                        sortField="OutArrivalDateTime3"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime3 ? moment(rowData.outArrivalDateTime3).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF3 Arrival DateTime"
                                    ></Column>

                                    <Column field="outFlightNo4" sortField="OutFlightNo4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Flight No" filter showFilterMenu={false} filterElement={OutFlightNo4FilterTemplate}></Column>
                                    <Column field="outAirlineName4" sortField="OutAirlineName4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Airline" filter showFilterMenu={false} filterElement={OutAirlineName4FilterTemplate}></Column>
                                    <Column
                                        field="outDepartFrom4"
                                        sortField="OutDepartFrom4"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Depart From"
                                        filter
                                        showFilterMenu={false}
                                        filterElement={OutDepartFrom4FilterTemplate}
                                    ></Column>
                                    <Column
                                        field="outDepartureDateTime4"
                                        sortField="OutDepartureDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outDepartureDateTime4 ? moment(rowData.outDepartureDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "18rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Departure DateTime"
                                    ></Column>
                                    <Column field="outArriveAt4" sortField="OutArriveAt4" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} header="OUTF4 Arrive At" filter showFilterMenu={false} filterElement={OutArriveAt4FilterTemplate}></Column>
                                    <Column
                                        field="outArrivalDateTime4"
                                        sortField="OutArrivalDateTime4"
                                        sortable
                                        body={(rowData) => <span>{rowData?.outArrivalDateTime4 ? moment(rowData.outArrivalDateTime4).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="OUTF4 Arrival DateTime"
                                    ></Column>

                                    <Column field="approveToSendTravelAgent" sortField="ApproveToSendTravelAgent" sortable header="Approve To Send Travel Agent" headerStyle={{ width: "10%", minWidth: "18rem" }} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column field="approveToSendParticipant" sortField="ApproveToSendParticipant" sortable header="Approve To Send Participant" headerStyle={{ width: "10%", minWidth: "18rem" }} style={{ overflowWrap: "anywhere" }}></Column>
                                    
                                    <Column field="accomodation" sortField="accomodation" sortable header="Need Accomodation" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={NeedAccomodationFilterTemplate}></Column>
                                    {/* <Column field="hotelName" header="Hotel" sortField="HotelName" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} filter showFilterMenu={false} filterElement={HotelFilterTemplate}></Column> */}
                                    <Column field="hotelName" header="Hotel" sortField="HotelName" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} filter showFilterMenu={false} filterElement={HotelFilterTemplate} body={HotelFilterBodyTemplate} filterField="Hotel" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="occupancy" sortField="Occupancy" header="Occupancy" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={OccupancyFilterTemplate} style={{ overflowWrap: 'anywhere' }}></Column>
                                    <Column field="isCheckedInYesNo" sortField="IsCheckedInYesNo" sortable header="Is Checked In" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsCheckedInFilterTemplate}></Column>
                                    <Column field="isArrivedYesNo" sortField="IsArrivedYesNo" sortable header="Is Arrived" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsArrivedFilterTemplate}></Column>
                                    <Column field="presentYesNo" sortField="PresentYesNo" sortable header="Present" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={PresentFilterTemplate}></Column>
                                    <Column field="dayTwoPresentYesNo" header="Day-2 Present" sortField="DayTwoPresent" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={DayTwoPresentfilterTemplate}></Column>
                                    <Column field="isTicketBooked" sortField="IsTicketBooked" sortable header="Is Ticket Booked" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TicketBookedFilterTemplate}></Column>
                                    <Column field="userregisteredby" header="Registered By" sortField="userregisteredby" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={RegisteredbyFilterTemplate}></Column>
                                    <Column
                                        field="registeredbynumber"
                                        header="Registered By Contact"
                                        sortField="registeredbynumber"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        filter
                                        showFilterMenu={false}
                                        filterElement={RegisteredbyNumberFilterTemplate}
                                    ></Column>
                                    <Column field="created" header="Registration Date" body={(rowData) => <span>{rowData?.created ? moment(rowData.created).format("DD/MM/YYYY HH:mm") : ""}</span>} sortField="Created" sortable headerStyle={{ width: "25%", minWidth: "15rem" }}></Column>
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                                <div className="hidden">
                                    {QrCodes.map((element, index) => (
                                        <QRCode
                                            id={`qrCodeEl${element.qrId}`}
                                            size={150}
                                            // value={qrCodeText}
                                            value={element.url}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};

export default ViewAllAttendees;
