import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as GlobalEnumData from "./../../enums/GlobalEnum";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { Tooltip } from "primereact/tooltip";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import userDetails from "../../services/userTokenDetails";

const AbsentSummary = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [ActionDisable, setActionDisable] = useState(true);
    const [id, setid] = useState(new Array());
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [dayOfEventScreen, setDayOfEventScreen] = useState();
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterAddress, setFilterAddress] = useState(null);
    const [filterSpeciality, setFilterSpeciality] = useState(null);
    const [HotelFilter, setHotelFilter] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const [InBatchfilter, setInBatchfilter] = useState(null);

    // Flags for show/hide columns
    const [enableFilter, setEnableFilter] = useState(false);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    var usrDetails = userDetails();

    let DayOfEvent = "";
    let ModifyParticipantList = [];

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        genderdropdowndata: null,
        filterMerchandiseIssued: null,
        filterCertificateIssued: null,
        filterPresent: null,
        fullName: null,
        filterHospitalName: null,
        filterEmail: null,
        filterMobileNo: null,
        filterCity: null,
        filterCountry: null,
        filterCardNo: null,
        filterAddress: null,
        filterSpeciality: null,
        filterBoardingCity: null,
        filterState: null,
        filterCompany: null,
        filterCountry: null,
        HotelFilter: null,
        inbatchfilter: null,
        serialnumber: null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        document.documentElement.style.setProperty("overflow", "hidden");
    }, []);

    //#region Common Methods

    // - Get All Participant Api
    const getparticipantlist = (isExportExcel = false, event, IsPresent = null) => {
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyFilter: lazyState.current.filterCompany ? lazyState.current.filterCompany.trim() : "",
                FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                City: lazyState.current.filterCity ? lazyState.current.filterCity.trim() : null,
                CertificateIssued: lazyState.current.filterCertificateIssued ? lazyState.current.filterCertificateIssued.code.trim() : null,
                Address: lazyState.current.filterAddress ? lazyState.current.filterAddress.trim() : null,
                Speciality: lazyState.current.filterSpeciality ? lazyState.current.filterSpeciality.trim() : null,
                BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity.trim() : null,
                State: lazyState.current.filterState ? lazyState.current.filterState.trim() : null,
                country: lazyState.current.filterCountry ? lazyState.current.filterCountry.trim() : null,
                InBatchBooking: lazyState.current.inbatchfilter ? lazyState.current.inbatchfilter.trim() : null,
                HotelFilter: lazyState.current.HotelFilter ? lazyState.current.HotelFilter.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
                Present: "False",
                OutFlightBookingStatusForAbsentAttendee: "TO BE CANCEL", // For OutFlightBookingStatus ! = "To Be Cancel"
                ParticipantHotel: true,
            })
            .then((Response) => {
                DayOfEvent = "Absent Summary";
                setDayOfEventScreen("Absent Summary");
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        Company: dataObj.company,
                        Batch: dataObj.inBatchBooking,
                        Hotel: dataObj.hotelName,
                        Email: dataObj.email,
                        "Mobile No": dataObj.mobileNo,
                        // "Certificate Issue": dataObj.certificateIssued == true ? "Yes" : dataObj.certificateIssued == false ? "No" : "",
                        // Hospital: dataObj.hospitalName,
                        // "Address": dataObj.address,
                        // "City": dataObj.cityname,
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            Company: dataObj.company,
                            Batch: dataObj.inBatchBooking,
                            Hotel: dataObj.hotelName,
                            Email: dataObj.email,
                            "Mobile No": dataObj.mobileNo,
                            // "Certificate Issue": dataObj.certificateIssued == true ? "Yes" : dataObj.certificateIssued == false ? "No" : "",
                            // "Address": dataObj.address,
                            // "City": dataObj.cityname,
                            // Hospital: dataObj.hospitalName,
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, DayOfEvent);
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                ModifyParticipantList = Response.data.data.map((TricoConfirmedData) => ({
                    ...TricoConfirmedData,
                    certificateIssued: TricoConfirmedData.certificateIssued === true ? "Yes" : TricoConfirmedData.certificateIssued === false ? "No" : "",
                }));
                setParticipantlist(ModifyParticipantList);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
        setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.filterCertificateIssued = null;
        lazyState.current.filterSpeciality = null;
        setFilterSpeciality(null);
        lazyState.current.filterBoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.filterState = null;
        setFilterState(null);
        lazyState.current.filterCountry = null;
        setFilterCountry(null);
        lazyState.current.filterCardNo = null;
        lazyState.current.filterAddress = null;
        setFilterAddress(null);
        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;

        lazyState.current.HotelFilter = null;
        setHotelFilter(null);

        lazyState.current.serialnumber = null;
        setSerialNumber(null);

        getparticipantlist(false, lazyState.current, null);
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl allowedPermissions={["list:certificategiven"]}>
                <div className="my-2">
                    <Button disabled={ActionDisable} label="Cancel Ticket" icon="fa fa-times" className="mr-2" onClick={() => TicketCancellation("Out")} outlined />
                </div>
            </AccessControl>
        );
    };

    //  TICKET CANCELLATION
    const TicketCancellation = (cancellationFor) => {
        setIsLoading(true);
        axios
            .put("/api/Participant/UpdateMultipleParticipantForCancellationById", {
                id: id.toString(),
                CancellationValue: cancellationFor,
            })
            .then((res) => {
                setIsLoading(false);
                setSelectedParticipant([]);
                setid([]);
                id.splice(0, id.length);
                setActionDisable(true);
                getparticipantlist(false, lazyState.current);
                toast.current.show({ severity: "success", summary: "Cancelled", detail: "Cancellation Request Sent To Travel Agent", life: 5000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:certificategiven"]}>
                    <Button
                        disabled={
                            lazyState.current.fullName == null &&
                            lazyState.current.filterHospitalName == null &&
                            lazyState.current.filterCompany == null &&
                            lazyState.current.filterEmail == null &&
                            lazyState.current.filterMobileNo == null &&
                            lazyState.current.filterCity == null &&
                            lazyState.current.filterBoardingCity == null &&
                            lazyState.current.HotelFilter == null &&
                            lazyState.current.inbatchfilter == null &&
                            lazyState.current.filterState == null &&
                            lazyState.current.filterCountry == null &&
                            lazyState.current.filterCardNo == null &&
                            lazyState.current.serialnumber == null &&
                            lazyState.current.filterCertificateIssued == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:certificategiven"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        onClick={() => {
                            getparticipantlist(true, null);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
    };
    //#endregion

    //#region Method for view attendees
    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };
    //#endregion

    //#region Method for filteration

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.filterCity = e.target.value ? e.target.value : null;
                        break;
                    case "address":
                        lazyState.current.filterAddress = e.target.value ? e.target.value : null;
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.filterState = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.filterCountry = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = e.target.value ? e.target.value : null;
                        break;
                        case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = null;
                        break;
                    case "city":
                        lazyState.current.filterCity = null;
                        break;
                    case "address":
                        lazyState.current.filterAddress = null;
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = null;
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = null;
                        break;
                    case "state":
                        lazyState.current.filterState = null;
                        break;
                    case "country":
                        lazyState.current.filterCountry = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = null;
                        break;
                        case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                }
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            }
        }
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const InBatchFilterTemplate = () => {
        return <InputText id="inbatch" value={InBatchfilter} placeholder="Enter In Batch" onChange={(e) => setInBatchfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const HotelFilterTemplate = () => {
        return <InputText id="Hotel" value={HotelFilter} placeholder="Enter Hotel" onChange={(e) => setHotelFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile no" onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CompanyFilterTemplate = () => {
        return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CityFilterTemplate = () => {
        return <InputText id="city" value={filterCity} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const AddressFilterTemplate = () => {
        return <InputText id="address" value={filterAddress} placeholder="Enter Address" onChange={(e) => setFilterAddress(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CertificateIssuedFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterCertificateIssued}
                options={GlobalEnumData.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterCertificateIssued = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Certificate Issued"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const SpecialityFilterTemplate = () => {
        return <InputText id="speciality" value={filterSpeciality} placeholder="Enter Speciality" onChange={(e) => setFilterSpeciality(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={filterBoardingCity} placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={filterState} placeholder="Enter State" onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={filterCountry} placeholder="Enter Country" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };
    //#endregion

    //#region Certification Given and Merchandise
    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        id.splice(0, id.length);
    };
    //#endregion

    //#endregion

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "speciality", header: "Speciality" },
        { field: "hospitalName", header: "Hospital" },
        { field: "countryname", header: "Country" },
        { field: "statename", header: "State" },
        { field: "cityname", header: "City" },
        { field: "boardingcityname", header: "Boarding City" },
    ];

    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Hospital":
                        if (lazyState.current.filterHospitalName != null) {
                            lazyState.current.filterHospitalName = null;
                            isFiltered = true;
                            return setFilterHospitalName(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.filterCity != null) {
                            lazyState.current.filterCity = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        }
                        break;
                    // case "Speciality":
                    //     if (lazyState.current.filterSpeciality != null) {
                    //         lazyState.current.filterSpeciality = null;
                    //         isFiltered = true;
                    //         return setFilterSpeciality(null);
                    //     }
                    //     break;
                    case "Boarding City":
                        if (lazyState.current.filterBoardingCity != null) {
                            lazyState.current.filterBoardingCity = null;
                            isFiltered = true;
                            return setFilterBoardingCity(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.filterState != null) {
                            lazyState.current.filterState = null;
                            isFiltered = true;
                            return setFilterState(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.filterCountry != null) {
                            lazyState.current.filterCountry = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current, null);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const sortColumn = {
        Speciality: "Speciality",
        Hospital: "HospitalName",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                sortField={sortColumn[col.header]}
                headerStyle={{ width: "15%", minWidth: "15rem" }}
                filter
                showFilterMenu={false}
                filterElement={col.header == "Hospital" ? HospitalFilterTemplate : col.header == "City" ? CityFilterTemplate : col.header == "Boarding City" ? BoardingCityFilterTemplate : col.header == "State" ? StateFilterTemplate : CountryFilterTemplate}
            />
        );
    });

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "DayOfEvent" } })}>
                    {" "}
                    <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };

    //#endregion

    return (
        <AccessControl allowedPermissions={["route:certificategiven"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-calendar mr-2"></i>
                                        {dayOfEventScreen}
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    filterDisplay={"row"}
                                    scrollHeight="calc(100vh - 250px)"
                                    scrollable
                                    selectionMode={"checkbox"}
                                    onAllRowsSelect={onAllRowSelect}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    dataKey="id"
                                    className="datatable-responsive"
                                    responsiveLayout="scroll"
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column frozen selectionMode="multiple" headerStyle={{ width: "0%", minWidth: "1rem" }}></Column>
                                    <Column field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column body={ViewAttendees} sortField="FullName" frozen field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate}></Column>
                                    <Column field="inBatchBooking" header="In Batch" sortField="InBatchBooking" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={InBatchFilterTemplate}></Column>
                                    <Column field="hotelName" sortField="HotelName" sortable header="Hotel" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={HotelFilterTemplate}></Column>
                                    <Column body={ButtonMailto} header="Email" sortField="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} header="Mobile No" sortField="MobileNo" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    {/* <Column field="hospitalName" header="Hospital" sortField="HospitalName" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} ></Column> */}
                                    {/* <Column field="cityname" header="City" sortField="cityname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CityFilterTemplate}></Column> */}
                                    {/* <Column field="address" header="Address" sortField="Address" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={AddressFilterTemplate}></Column> */}
                                    {/* <Column field="certificateIssued" header="Certificate Issue" sortField="CertificateIssued" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CertificateIssuedFilterTemplate} ></Column> */}
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};
export default AbsentSummary;
